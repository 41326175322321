import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const AlertBox = ({ text, color, colorIcon, colorText, borderRadius }) => {
    return (
        <Box sx={{ paddingBottom: '20px' }}>
            <div style={{
                display: 'flex',
                alignItems: 'flex-start',
                padding: '8px 16px',
                borderRadius: `${borderRadius}`,
                backgroundColor: `rgba(${color}, 0.1)`,
            }}>
                <ErrorOutlineIcon sx={{ color: `rgb(${colorIcon})`, marginRight: '15px' }} /> {/*rgb(102, 57, 1) */}
                <Typography sx={{ color: `rgb(${colorText})`, maxWidth: 'calc(100% - 24px)' }}>{text}</Typography> {/*rgb(102, 57, 1) */}
            </div>
        </Box>
    );
};

export default AlertBox;