import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import BasicModal from "../../../components/GenericModal";
import Link from "@mui/material/Link";
import AddIcon from "@mui/icons-material/Add";
import CategoryManages from "../../../api/categoryManages";
import { useAuth } from "user-context-fran-dev";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  initValAddCategory,
  valSchAddCategory,
} from "../../../utils/formHelper";
import * as Yup from "yup";
import { useFormik } from "formik";
import CountWords from "../../../components/CountWords";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';


const AddCategory = ({ open, setOpen, update, edit, data }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const manageCategory = new CategoryManages();

  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [open]);

  const setCategory = async (formData) => {
    let response = await manageCategory.postDataAuth(formData, logout);
    if (response.data.data) {
      setOpen(false);
    }
    sendNotification(UseerrorOrsuccess(response));
    update();
  };
  const updateCategory = async (formData, id) => {
    console.log(formData);
    console.log(id);
    let response = await manageCategory.editDataAuth(id, formData, logout);
    if (response.data.data) {
      setOpen(false);
    }
    sendNotification(UseerrorOrsuccess(response));
    update();
  };

  const formik = useFormik({
    initialValues: initValAddCategory(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchAddCategory()),
    onSubmit: async (formData) => {
      setLoading(true);
      console.log(formData)

      if (!edit) {
        setCategory(formData);
      } else {
        updateCategory(formData, data.id);
      }

      setOpen(false);
      setLoading(false);
    },
  });

  return (
    <div>
      <BasicModal
        open={open}
        setOpen={setOpen}
        title={edit ? "Actualizar categoría" : "Agregar categoría"}
      >
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container  mt={"16px"} /* rowSpacing={"8px"} */ columnSpacing={"8px"}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  label="Nombre de la categoría"
                  margin="dense"
                  size="small"
                  variant="outlined"
                  autoComplete="none"
                  inputProps={{ maxLength: 50 }}
                  helperText={formik.touched.name ? formik.errors.name : ""}
                />
                <CountWords length={formik.values.name.length} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" >
                Mostrarse en:
                </Typography>

                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-evenly">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="display_on_store"
                        checked={formik.values.display_on_store}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.display_on_store
                            ? formik.errors.display_on_store
                            : ""
                        }
                      />
                    }
                    label="Tienda en línea"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="display_on_pos"
                        checked={formik.values.display_on_pos}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.display_on_pos
                            ? formik.errors.display_on_pos
                            : ""
                        }
                      />
                    }
                    label="Punto de venta"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="display_on_catalog"
                        checked={formik.values.display_on_catalog}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.display_on_catalog
                            ? formik.errors.display_on_catalog
                            : ""
                        }
                      />
                    }
                    label="Catálogo"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
              <FormControlLabel
               
               control={
                 <Switch 
                 name="is_active"
                 checked={formik.values.is_active}
                 onChange={formik.handleChange}
                 inputProps={{ 'aria-label': 'controlled' }}
               />
               }
               label="Activar"
             />
              </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={6}
                  lg={3}
                  sx={{ marginTop: "16px" }}
                >
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    {edit ? "Actualizar categoría" : "Agregar categoría"}
                  </LoadingButton>
                </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </BasicModal>
    </div>
  );
};

export default AddCategory;
