/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormHelperText
} from "@mui/material";
import { useAuth } from "user-context-fran-dev";
import { DomainList } from "../../api/landing/domainList";
import { BusinessSettings } from "../../api/businessManages";
import ProductLink from "./productLink";
import CategoryLink from "./categoryLink";


const LinkType = ({ formik, }) => {
    const [list, setList] = useState("");
    const [pages, setPages] = useState("")
    const { logout } = useAuth();
    const [openProduct, setOpenProduct] = useState("");
    const [openCategory, setOpenCategory] = useState("");

    useEffect(() => {

        if (pages) {
            GetListPages(pages.domain)
        }
    }, [pages])

    useEffect(() => {
        UpdateStyles()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const UpdateStyles = () => {
        (async () => {
            let useBusines = new BusinessSettings();
            let response = await useBusines.getAllByIdAuth("1", logout);
            setPages(response.data);
        })();
    };

    const GetListPages = async (domain) => {
        let irequestFilter = [];
        irequestFilter.push({ key: "domain", value: `${domain}` }); 
        let getArticle = new DomainList();
        let response = await getArticle.filter(irequestFilter);
        setList(response.data);
    }

    if (formik.values.link === 1) { // * Selector PAGE
        return (<>
            {list && <FormControl
                fullWidth
                sx={{ /* width: "150px", */ paddingLeft: '18px', paddingRight: '0px' }}
                size="small"
            >
                <InputLabel id="demo-simple-select-filled-label" sx={{ /* width: "150px", */ paddingLeft: '25px' }}
                >
                    Seleccionar
                </InputLabel>
                <Select
                    name={"button_action"}
                    labelId="demo-simple-select-standard-label"
                    label={"Seleccionar"}
                    value={formik.values.button_action}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.button_action &&
                        Boolean(formik.errors.button_action)
                    }
                >
                    {list && Object.values(list).map((data, index) => ( 
                        <MenuItem key={index} value={data.link!=='/root'? data.link:'/' }>
                            {data.name}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText sx={{ display: formik.touched.button_action ? "" : "none", color: "error.main" }}>
                    {formik.touched.button_action ? formik.errors.button_action : ""}
                </FormHelperText>
            </FormControl>}
        </>);
    } else if (formik.values.link === 2) {  // * Selector PRODUCTS
        return (
            <>
                <TextField
                    fullWidth
                    sx={{ cursor: "pointer", paddingLeft: '18px', paddingRight: '0px' }}
                    className="tasaqui"
                    name="button_action"
                    value={formik.values.button_action}
                    //onChange={formik.handleChange}
                    error={
                        formik.touched.button_action && Boolean(formik.errors.button_action)
                    }
                    label={`Seleccionar producto`}
                    onClick={() => { setOpenProduct(true) }}

                    size="small"
                    variant="outlined"
                    autoComplete='none'
                    helperText={
                        formik.touched.button_action ? formik.errors.button_action : ""
                    }
                    InputLabelProps={{
                        sx: { paddingLeft: '25px' }
                    }}
                />
                <ProductLink open={openProduct} setOpen={setOpenProduct} formiko={formik} />
            </>
        );
    } else if (formik.values.link === 3) {  // * Selector CATEGORY
        return (<>

            <TextField
                sx={{ cursor: "pointer", paddingLeft: '18px', paddingRight: '0px' }}
                name="button_action"
                value={formik.values.button_action}
                //onChange={formik.handleChange}
                fullWidth
                error={
                    formik.touched.button_action && Boolean(formik.errors.button_action)
                }
                label={`Seleccionar Categoría`}
                onClick={() => { setOpenCategory(true) }}

                size="small"
                variant="outlined"
                autoComplete='none'
                helperText={
                    formik.touched.button_action ? formik.errors.button_action : ""
                }
                InputLabelProps={{
                    sx: { paddingLeft: '25px' }
                }}
            />

            <CategoryLink open={openCategory} setOpen={setOpenCategory} formiko={formik} />
        </>);
    } else if (formik.values.link === 4) {  // * Selector EXTERNAL LINK
        return (<>
            <TextField
                sx={{ cursor: "pointer", paddingLeft: '18px', paddingRight: '0px' }}
                fullWidth
                name="button_action"
                value={formik.values.button_action}
                onChange={formik.handleChange}
                error={
                    formik.touched.button_action && Boolean(formik.errors.button_action)
                }
                label={`Enlace externo`}


                size="small"
                variant="outlined"
                autoComplete='none'

                helperText={
                    formik.touched.button_action ? formik.errors.button_action : ""
                }
                InputLabelProps={{
                    sx: { paddingLeft: '25px' }
                }}
            /></>);
    } else {
        return (<></>);
    }

};

export default LinkType;