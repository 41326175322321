/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { GenericHeaderSubTitle, GenericHeaderTitle } from "../GenericHeader";
import { Grid, Typography, TextField, Button, Input, Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValDescription,
  valSchDescription,
  initValKeyWord,
  valSchKeyWord,
} from "../../utils/formHelper";
import CountWords from "../CountWords";
import BusinessManages, { BusinessMetaData, BusinessSettingsColors, BusinessTypography } from "../../api/businessManages";
import { useAuth } from "user-context-fran-dev";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse";
import { LoadingButton } from "@mui/lab";
import { BusinessKeyword, BusinessImages } from "../../api/businessManages";
import Chip from "@mui/material/Chip";
import { useSnackbar } from "notistack";
/* import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined"; */
import ImgEdit from "./ImgEdit";
import { useNavigate } from "react-router-dom";
import SocialMedia from "../../pages/admin/business/socialMedia";


const BUSSINES = 1;

const MetaData = ({ data, update, styles, updateStyles }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  // eslint-disable-next-line no-unused-vars
  const [msg, sendNotification] = useNotification();
  const [keyword, setKeyword] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState();
  const [open, setOpen] = useState(false);

  const [file2, setFile2] = useState();
  const [open2, setOpen2] = useState(false);

  const [file3, setFile3] = useState();
  const [open3, setOpen3] = useState(false);

  const [color1, setColor1] = useState("#ffffff")
  const [color2, setColor2] = useState("#ffffff")

  const [loadingColor, setLoadingColor] = useState(false)

  const Navigate = useNavigate();

  const [business, setBusiness] = useState("");


  /*   useEffect(() => {
      UpdateBusinessInfo();
    }, []);
  
    const UpdateBusinessInfo = () => {
      (async () => {
        let useBusines = new BusinessManages();
        let response = await useBusines.getAllAuth(logout);
        setBusiness(response.data);
        
      })();
    }; */

  const changeInput = (e) => {
    console.log(e.currentTarget.files);
    console.log(URL.createObjectURL(e.currentTarget.files[0]));
    if (e.currentTarget.files.length <= 1) {
      setFile(e.currentTarget.files[0]); //URL.createObjectURL()
      console.log(URL.createObjectURL(e.currentTarget.files[0]));
      setOpen(true);
      console.log("open");
    } else {
      enqueueSnackbar("Maximo 1 imagen ", {
        autoHideDuration: 1000,
        variant: "warning",
      });
    }
  };

  const addLogos = (file) => {
    (async () => {
      var formData = new FormData();
      formData.append("imgLogoRect", file);
      let get = new BusinessImages();
      let response = await get.uploadDoc(
        BUSSINES,
        formData,
        logout
      ); /*   uploadImg post*/
      sendNotification(UseerrorOrsuccess(response));
      update();
    })();
  };

  const changeInput2 = (e) => {
    console.log(e.currentTarget.files);
    console.log(URL.createObjectURL(e.currentTarget.files[0]));
    if (e.currentTarget.files.length <= 1) {
      setFile2(e.currentTarget.files[0]); //URL.createObjectURL()
      console.log(URL.createObjectURL(e.currentTarget.files[0]));
      setOpen2(true);
      console.log("open");
    } else {
      enqueueSnackbar("Maximo 1 imagen ", {
        autoHideDuration: 1000,
        variant: "warning",
      });
    }
  };

  const addLogos2 = (file) => {
    (async () => {
      var formData = new FormData();
      formData.append("imgFavicon", file);
      let get = new BusinessImages();
      let response = await get.uploadDoc(
        BUSSINES,
        formData,
        logout
      ); /*   uploadImg*/
      sendNotification(UseerrorOrsuccess(response));
      update();
    })();
  };

  const changeInput3 = (e) => {
    console.log(e.currentTarget.files);
    console.log(URL.createObjectURL(e.currentTarget.files[0]));
    if (e.currentTarget.files.length <= 1) {
      setFile3(e.currentTarget.files[0]); //URL.createObjectURL()
      console.log(URL.createObjectURL(e.currentTarget.files[0]));
      setOpen3(true);
      console.log("open");
    } else {
      enqueueSnackbar("Maximo 1 imagen ", {
        autoHideDuration: 1000,
        variant: "warning",
      });
    }
  };

  const addLogos3 = (file) => {
    (async () => {
      var formData = new FormData();
      formData.append("imgLogoSqr", file);
      let get = new BusinessImages();
      let response = await get.uploadDoc(
        BUSSINES,
        formData,
        logout
      ); /*   uploadImg*/
      sendNotification(UseerrorOrsuccess(response));
      update();
    })();
  };

  /*     useEffect(() => {
      window.localStorage.setItem("menu", menu);
    }, [location]); */

  useEffect(() => {
    UpdateKeyword();
  }, []);

  useEffect(() => {
    setColor1(styles?.color_primary || "#ffffff")

    setColor2(styles?.color_secondary || "#ffffff")

  }, [styles]);

  const UpdateKeyword = () => {
    (async () => {
      let useBusines = new BusinessKeyword();
      let response = await useBusines.getAllAuth(logout);
      setKeyword(response.data);
    })();
  };
  const AddKeyword = (formData) => {
    (async () => {
      if (keyword.length < 5) {
        let useBusines = new BusinessKeyword();
        let response = await useBusines.postDataAuth(formData, logout);
        UpdateKeyword();
      } else {
        enqueueSnackbar("Máximo de keywords alcanzado", {
          variant: "error",
        });
      }
      setLoading(false);
    })();
  };

  const UpdateInfo = async (formData) => {
    let updateTaxt = new BusinessMetaData();
    const response = await updateTaxt.editDataAuth(BUSSINES, formData, logout);
    //sendNotification(UseerrorOrsuccess(response));
    update();
    setLoading(false);
  };

  const formikDesc = useFormik({
    initialValues: initValDescription(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchDescription()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      console.log(formData);

      UpdateInfo(formData);
    },
  });

  const formikKeyWord = useFormik({
    initialValues: initValKeyWord(),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchKeyWord(keyword)),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      console.log(formData);

      AddKeyword(formData);

      resetForm();
    },
  });

  const handleDelete = async (id) => {
    let useBusines = new BusinessKeyword();
    let response = await useBusines.deleteAuth(id, logout);
    UpdateKeyword();
  };

  const changesColor = async () => {
    setLoadingColor(true)
    const formData = {
      color_primary: color1,
      color_secondary: color2
    }
    let seColor = new BusinessSettingsColors();
    await seColor.postDataAuth(formData, logout);

    setLoadingColor(false)
    updateStyles();
  };


  const changeFile = (e) => {
    console.log(e.currentTarget.files);
    console.log(URL.createObjectURL(e.currentTarget.files[0]));
    if (e.currentTarget.files.length <= 1) {
      //setFile(e.currentTarget.files[0]);
      (async () => {
        var formData = new FormData();
        formData.append("fileUpload", e.currentTarget.files[0]);
        let get = new BusinessTypography();
        let response = await get.uploadImg(formData, logout); //uploadImg post --- uploadDoc put
        updateStyles();
        //sendNotification(UseerrorOrsuccess(response));

      })();
    } else {
      enqueueSnackbar("Maximo 1 archivo ", {
        autoHideDuration: 1000,
        variant: "warning",
      });
    }
  };

  return (
    <Box sx={{ marginBottom: "32px" }}>
      <GenericHeaderTitle title="Información de la página" />
      <Grid container>
        <Grid item xs={12} mt="16px">
          {/*    <Typography variant='body1' sx={{color: "text.secondary"}}>
                    La siguiente información se puede editar desde la app de Administración, mantén tu información actualizada, ya que esta se mostrara en tu página de aterrizaje.
                    </Typography> */}
          {/*   <Grid item xs={12} mt={"16px"}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">METADATA</Typography>
           {/*    <Button
                onClick={() => {
                  window.localStorage.setItem("menu", 1);
                  Navigate("/administration/business");
                }}
              >
                Actualizar
              </Button> 
            </Box>
            <Divider
              sx={{ marginBottom: "16px", backgroundColor: "text.secondary" }}
            />
          </Grid> */}
          <GenericHeaderSubTitle title="METADATA" />
        </Grid>
        <Grid item xs={12} mt="32px">
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {data?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} mt={"6px"}>
          <Stack spacing={"8px"}>
            <Typography variant="body1">
              {`${data?.address?.street ? data?.address?.street : ""} #${data?.address?.ext_number ? data?.address?.ext_number : ""
                }`}
            </Typography>
            <Typography variant="body1">
              {`${data?.address?.suburb ? data?.address?.suburb : ""}, C.P. ${data?.address?.postal_code ? data?.address?.postal_code : ""
                },`}
            </Typography>
            <Typography variant="body1">
              {`${data?.address?.city ? data?.address?.city : ""}, ${data?.address?.state ? data?.address?.state : ""
                }`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} mt={"6px"}>
          <Stack spacing={"8px"}>
            <Typography variant="body1">
              {`${data?.address?.phone} `}
            </Typography>
            <Typography variant="body1">{`${data?.email}`}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} mt={"32px"}>
          <SocialMedia data={data.social_networks} UpdateInfo={update} />
        </Grid>

        {/*   <Grid item xs={12} mt={"32px"}>
          <Grid container spacing={"12px"}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={true}
                label="Facebook"
                size="small"
                value={data?.social_networks?.facebook}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={true}
                label="YouTube"
                size="small"
                value={data?.social_networks?.youtube}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={true}
                label="Instagram"
                size="small"
                value={data?.social_networks?.instagram}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={true}
                label="LinkedIn"
                size="small"
                value={data?.social_networks?.linkedin}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={true}
                label="WhatsApp"
                size="small"
                value={data?.social_networks?.whatsapp}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={true}
                label="Tik Tok"
                size="small"
         
                value={data?.social_networks?.tiktok}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={12}>
          <Divider
            sx={{
              marginTop: "32px",
              marginBottom: "16px",
              backgroundColor: "text.secondary",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            Actualiza la siguiente información para mejorar el posicionamiento
            de tu página.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={formikDesc.handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={10} sx={{ marginTop: "16px" }}>
                <TextField
                  fullWidth
                  name="description"
                  multiline
                  label="Descripción breve de tu negocio"
                  size="small"
                  value={formikDesc.values.description}
                  onChange={formikDesc.handleChange}
                  InputProps={{
                    maxLength: 160,
                  }}
                  error={
                    formikDesc.touched.description &&
                    Boolean(formikDesc.errors.description)
                  }
                  helperText={
                    formikDesc.touched.description
                      ? formikDesc.errors.description
                      : ""
                  }
                />
                <CountWords
                  length={formikDesc.values.description.length}
                  max="160"
                />
              </Grid>
              <Grid item xs={12} sm={2} sx={{ marginTop: "16px" }}>
                <LoadingButton loading={loading} fullWidth type="submit">
                  Actualizar
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={formikKeyWord.handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={10} sx={{ marginTop: "16px" }}>
                <TextField
                  fullWidth
                  name="name"
                  multiline
                  label="Keyword"
                  size="small"
                  value={formikKeyWord.values.name}
                  onChange={formikKeyWord.handleChange}
                  InputProps={{
                    maxLength: 160,
                  }}
                  error={
                    formikKeyWord.touched.name &&
                    Boolean(formikKeyWord.errors.name)
                  }
                  helperText={
                    formikKeyWord.touched.name ? formikKeyWord.errors.name : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2} sx={{ marginTop: "16px" }}>
                <LoadingButton loading={loading} fullWidth type="submit">
                  Agregar
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "8px" }}>
          {keyword && (
            <Stack direction="row" spacing={1}>
              {keyword.map((data, index) => (
                <Chip
                  key={index}
                  label={data.name}
                  onDelete={() => handleDelete(data.id)}
                />
              ))}
            </Stack>
          )}
          <CountWords length={keyword.length} max="5" />
        </Grid>

        <Grid item xs={12} mt={"16px"}>
          {/*      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Estilo</Typography>
          </Box>
          <Divider
            sx={{ marginBottom: "16px", backgroundColor: "text.secondary" }}
          /> */}
          <GenericHeaderSubTitle title="Estilo" />
        </Grid>


        <Grid item xs={12}>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <label style={{}}>
              <input
                hidden
                sx={{ display: "none" }}
                type="file"
                accept=".ttf, .otf "
                onChange={changeFile}
              ></input>
              <Button variant="contained" component="span">
                Tipografía
              </Button>
            </label>{" "}
            {styles && <Typography ml={"8px"}> {styles?.typography ? styles?.typography : ""} </Typography>}
          </Box>
          <Typography variant="body1" mt={"8px"}>
            Puedes explorar más tipografías en{" "}
            <a href="https://fonts.google.com/" target="_blank">
              Google fonts
            </a>
            , sube el archivo tipo <strong>otf</strong> o <strong>ttf</strong>{" "}
            en <strong>regular 400</strong>
          </Typography>
          {styles && <Box sx={{ marginTop: "32px", marginBottom: "32px" }}>
            <TextField
              label="Color principal"
              size="small"
              name="bg_color1"
              value={color1}
              onChange={(e) => setColor1(e.target.value)}
              sx={{ width: "100px", marginRight: "8px" }}
              type="color" />


            <TextField
              label="Color secundario"
              size="small"
              name="bg_color2"
              value={color2}
              onChange={(e) => setColor2(e.target.value)}
              sx={{ width: "100px" }}
              type="color" />
            <LoadingButton loading={loadingColor} onClick={() => changesColor()} sx={{ marginLeft: "8px" }}>Guardar</LoadingButton>
          </Box>}

        </Grid>

        <Grid item xs={12} mt={"16px"}>
          {/*   <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Recursos Graficos</Typography>
          </Box>
          <Divider
            sx={{ marginBottom: "16px", backgroundColor: "text.secondary" }}
          /> */}
          <GenericHeaderSubTitle title="Recursos Gráficos" />
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          {data?.favicon && (
            <img src={data.favicon} style={{ margin: "0px 8px", width: 64, height: 64, objectFit: "cover" }} />
          )}
          {!data.favicon && (
            <Box
              sx={{
                width: "64px",
                height: "64px",
                border: "2px dashed #000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              64 x 64
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label>
              <Input
                hidden
                sx={{ display: "none" }}
                type="file"
                multiple
                onChange={changeInput2}
              ></Input>
              <Button
                /* startIcon={<UploadOutlinedIcon />} */

                component="span"
              >
                Subir icono
              </Button>
            </label>
            <Typography>64 x 64</Typography>
          </Box>
          {file2 && open2 && (
            <ImgEdit
              open={open2}
              handleClose={setOpen2}
              file={file2}
              size={{ height: 64, width: 64 }}
              update={addLogos2}
            />
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: "flex", alignItems: "center", marginTop: "24px" }}
        >
          {data?.logo_rect && (
            <img
              src={data.logo_rect}
              style={{ margin: "0px 8px", width: "231px", height: "42px" }}
            />
          )}
          {!data.logo_rect && (
            <Box
              sx={{
                width: "254px",
                height: "60px",
                border: "2px dashed #000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              462 x 85
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label>
              <Input
                hidden
                sx={{ display: "none" }}
                type="file"
                multiple
                onChange={changeInput}
              ></Input>
              <Button
                /* startIcon={<UploadOutlinedIcon />} */

                component="span"
              >
                Subir logotipo
              </Button>
            </label>
            <Typography>462 x 85</Typography>
          </Box>
          {file && open && (
            <ImgEdit
              open={open}
              handleClose={setOpen}
              file={file}
              size={{ height: 85, width: 462 }}
              update={addLogos}
            />
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: "flex", alignItems: "center", marginTop: "24px" }}
        >
          {data?.logo_sqr && (
            <img
              src={data.logo_sqr}
              style={{ margin: "0px 8px", width: "256px", height: "157px" }}
            />
          )}
          {!data.logo_sqr && (
            <Box
              sx={{
                width: "256px",
                height: "157px",
                border: "2px dashed #000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              600 x 315
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label>
              <Input
                hidden
                sx={{ display: "none" }}
                type="file"
                multiple
                onChange={changeInput3}
              ></Input>
              <Button
                /* startIcon={<UploadOutlinedIcon />} */

                component="span"
              >
                Subir imagen destacada
              </Button>
            </label>
            <Typography>600 x 315</Typography>
          </Box>
          {file3 && open3 && (
            <ImgEdit
              open={open3}
              handleClose={setOpen3}
              file={file3}
              size={{ height: 315, width: 600 }}
              update={addLogos3}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MetaData;
