import * as React from "react";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {/*  NavLink, */ Outlet, useNavigate, useParams } from "react-router-dom";
import { getData } from "../api/data/inicioData";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { useAuth } from 'user-context-fran-dev';
import GenericUserMenu from "../components/GenericUserMenu";
import { APPNAME } from "../utils/constants";
/* import Footer from "../components/Footer"; */
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Grid } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';


const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    /* width: `calc(100% - ${drawerWidth}px)`, */
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));




export default function MenuDrawer(props) {
  const navigate = useNavigate();
  const { id, /* page */ } = useParams();
  const [menus, setMenus] = React.useState(getData(parseInt(window.localStorage.getItem('menu'))))
  if (window.localStorage.getItem('menu')) {
    if (menus.id !== parseInt(window.localStorage.getItem('menu'))) {
      console.log("menu", getData(parseInt(window.localStorage.getItem('menu'))))
      setMenus(getData(parseInt(window.localStorage.getItem('menu'))))
      /* console.log(menus) */
    }
  }

  /*  var menus =  getData(parseInt(window.localStorage.getItem('menu'))) */

  const [open, setOpen] = React.useState(true);
  const { logout, auth, online } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);
  /* const [anchorE2, setAnchorE2] = React.useState(null); */
  const isCellPhone = window.innerWidth < 769 ? true : false;
  const openMenu = Boolean(anchorEl);


  useEffect(() => {
    if (props.posDrawer == 'false') {
      setOpen(false)
    }
    function upDateMenu() {
      setMenus(getData(parseInt(window.localStorage.getItem('menu'))))
      /* console.log(menus) */
      /* menus =  getData(parseInt(window.localStorage.getItem('menu'))) */
      /* console.log(menus) */
    }
    if (menus) {
      if (menus.id !== parseInt(window.localStorage.getItem('menu'))) {
        upDateMenu()
      }
    } else {
      /* console.log("su maere 222") */
      navigate('/home')
    }

  }, [props.reload, id]);

  const handleDrawer = () => {
    setOpen(!open);
  };
  const handleClosePhone = () => {
    if (isCellPhone && open) {
      setOpen(!open)
    }
  }



  return (
    <>
      {menus &&
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) =>
                theme.zIndex.drawer + 1,
            }}

            open={open}
          >
            <Toolbar> {/* ESTE ES EL NAV BAR MORADO QUE TIENE TODO EN SI */}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
              >
                <MenuIcon />
              </IconButton>

              <Typography
                variant="h6"
                noWrap
                component="div"
                style={{ marginLeft: "10px" }}
              >
                <strong>{`${menus.title ? menus.title : ''}`}</strong>
              </Typography>


              <div style={{ position: "fixed", right: "2%" }}>
                <Grid container>
                  <Grid sx>

                    {/* {online &&
                      <Tooltip title="Conexión a notificaciones en tiempo real">
                        <LinkIcon style={{ textAlign: 'center', color: 'green' }} sx={{ marginTop: '13px' }} />
                      </Tooltip>
                    }
                    {!online &&
                      <Tooltip title="Conexión a notificaciones en tiempo real">
                        <LinkOffIcon style={{ textAlign: 'center', color: 'red' }} sx={{ marginTop: '13px' }} />
                      </Tooltip>
                    } */}

                  </Grid>
                  {/* <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleMenu}
            >
              <SettingsIcon />
            </IconButton> */}
                  <Grid>
                    <GenericUserMenu changeMode={props.changeMode} mode={props.mode} /> {/*ESTE ES EL ICONO DONDE PODEMOS CERRAR SESION */}
                  </Grid>
                </Grid>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer   //TODO Y LO DE ADENTRO DE LIST, ES EL DRAWER LIST QUE ENSEÑA EL MENU DE METADATA, KESKICREATOR, ETC ETC
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"

            open={open}
          >
            <DrawerHeader>
            </DrawerHeader>
            <Divider />

            <List>
              {menus.menu.map((data, index) => (
                <div key={index}>
                  {!data?.notfinish && <div key={data.text} >
                    {!data.nestedMenu ? (
                      <ListItem button key={data.text}
                        onClick={() => {

                          if (data.comeback) {
                            window.localStorage.setItem('menu', data.comeback)
                            navigate(data.url);
                            return
                          }

                          if (data.url && id) {
                            navigate(`${data.url}/${id}`)
                            return
                          }
                          if (data.url) {
                            navigate(data.url);
                            return
                          }
                          /*                   console.log(id)
                                            debugger */


                        }}>
                        <ListItemIcon>{data.icon}</ListItemIcon>
                        <ListItemText primary={data.text} />
                      </ListItem>

                    ) : (<NestedList data={data} menus={menus} />)}
                    {data.gohome && <Divider />}

                  </div>}
                </div>
              ))}
            </List>
          </Drawer>
          <Main /* id="main-class-container" */ onClick={handleClosePhone} open={isCellPhone ? false : open} sx={{ padding: 0 }}  /* style={{paddingLeft: '16px', paddingRight: '16px'}} */>

            {/*             <div style={{height: '94vh' }} onClick={handleClosePhone}>

        </div> */}
            <DrawerHeader />
            <Outlet />

            {/*            <Footer /> */}
          </Main>

        </Box>
      }
    </>
  );
}

const NestedList = ({ data, menus }) => {
  const [open, setOpen] = useState(true)
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [dnMenu, setDnMenu] = useState("")
  const handleClick = () => {
    setOpen(!open);
  }
  useEffect(() => {
    if (data.hasOwnProperty("dynamicMenu")) {
      setDnMenu(data.dynamicMenu(logout));
      setOpen(false)
    }
  }, [])

  if (data.hasOwnProperty("dynamicMenu")) {
    return (
      <>{dnMenu &&
        <>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>{data.icon} </ListItemIcon>
            <ListItemText primary={data.text} />
            {open ? <ExpandLess /> : <ExpandMore />}  {/* open */}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit> {/* open */}
            <List component="div" disablePadding>
              {dnMenu && dnMenu.map((data, index) => (
                <ListItem key={index} button sx={{ pl: 4 }}
                  onClick={() => { if (data.url) navigate(data.url); }}>
                  <ListItemIcon>{data.icon}</ListItemIcon>
                  <ListItemText primary={data.text} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      }

      </>
    );

  } else {
    return (
      <>
        <ListItem button onClick={handleClick}>
          <ListItemIcon>{data.icon} </ListItemIcon>
          <ListItemText primary={data.text} />
          {open ? <ExpandLess /> : <ExpandMore />}  {/* open */}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit> {/* open */}
          <List component="div" disablePadding>
            {data.nestedMenu.map((data, index) => (
              <ListItem key={index} button sx={{ pl: 4 }}
                onClick={() => { if (data.url) navigate(data.url); }}>
                <ListItemIcon>{data.icon}</ListItemIcon>
                <ListItemText primary={data.text} />
              </ListItem>
            ))}
          </List>
        </Collapse>

      </>
    );
  }

}
