/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Container from "../../layout/gridContainer";
import {
    Box,
    Button,
    Grid,
    Typography
} from "@mui/material";
import { SectionsCreator, ImageCreator, SubcomponentCreator, ComponentCreator } from "../../api/landing/creatorManages";
import { useAuth } from "user-context-fran-dev";
import { useNavigate, useParams } from "react-router-dom";
import { Sections } from 'keski_lib_catalog'
import { styled } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import Element from "../keskiCreator/element";
import PreviewModal from "../keskiCreator/previewModal";
import EditModal from "../keskiCreator/editModal";
import TitleList from "./titleList";

const DetailCreatorElement = ({ view }) => {
    console.log('ID recibido:', view);

    const [data, setData] = useState()
    const [sections, setSections] = useState()
    const [bgStyle, setBgStyle] = useState(1);
    const [list, setList] = useState("");
    const { logout } = useAuth();
    const { id } = useParams()
    const [currentSection, setCurrentSection] = useState(view) //Ya del otro lado pasamos la prop view, el id, no se necesita volver a poner id useState(id)
    const Navigate = useNavigate();
    const [requirements, setRequirements] = useState()
    const [edit, setEdit] = useState()
    const [loading, setLoading] = useState(false)
    const SECA = new Sections()
    const [openEdit, setOpenEdit] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);

    const UpdateSection = async (setLoading) => {
        let get = new SectionsCreator();
        let response = await get.getAllByIdAuth(view, logout); //Checar aqui tambien que show (view, logout)
        setData(response.data);
        setRequirements({ section: response.data.type, layout: response.data.component.layout })
        setBgStyle(response.data.component.bg_type)
        setLoading(false)
    }

    const UpdateSectionsList = async () => {
        let get = new SectionsCreator();
        /*   let response = await get.getAllAuth(logout); */
        let irequestFilter = []
        irequestFilter.push({ 'key': 'view', 'value': view })
        let response = await get.filter(irequestFilter, logout);
        setSections(response.data)
    }

    const deleteElement = async (id) => {
        let updateAddress = new SubcomponentCreator();
        await updateAddress.deleteAuth(id, logout);
        UpdateSection(setLoading);
    };

    // Actualizar currentSection con el valor de view cuando view cambie cada vez que cliqueemos diferente seccion
    useEffect(() => {
        setCurrentSection(view);
    }, [view]);

    useEffect(() => {
        UpdateSection(setLoading)
        UpdateSectionsList()
        setList(SECA.getSectionList())
    }, [currentSection]);

    return (
        <Container>
            {requirements && openEdit && <EditModal data={data} open={openEdit} setOpen={setOpenEdit} requirements={requirements} SECA={SECA} update={UpdateSection} edit={edit} setEdit={setEdit} />}
            <PreviewModal open={openPreview} setOpen={setOpenPreview} SECA={SECA} data={data} />
            <Grid container>
                <Grid item xs={12}>
                    {data && <TitleList bgStyle={bgStyle} data={data} UpdateSection={UpdateSection} setOpenPreview={setOpenPreview} />}
                </Grid>
                <Grid item xs={12} mt={"16px"}>

                </Grid>
                <Grid container>
                    <Grid item xs={12} mb={"2px"}>
                        <Typography sx={{ fontWeight: "bold" }}>
                            Lista de Elementos
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mb={"12px"}>
                        <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                            {data?.component?.subcomponent && data.component.subcomponent.map((row, index) => (
                                <Element key={index} row={row} setOpenEdit={setOpenEdit} setEdit={setEdit} deleteElement={deleteElement} />
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {data && <Button sx={{ textTransform: 'none' }} disabled={data.component.type === 0 && data.component.subcomponent.length >= 1 ? true : false} onClick={() => setOpenEdit(true)} endIcon={<AddIcon />}>Agregar elemento</Button>}
            </Box>
        </Container>
    );
};


export default DetailCreatorElement;
