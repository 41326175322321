import { Button, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import HeaderOrder from './header';
import { useLocation } from 'react-router-dom';
import BodyOrder from './bodyOrder';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { GetAllWhtParams } from '../../api/common/orders';
import ShareIcon from '@mui/icons-material/Share';


const ViewOrder = () => {
  const [order, setOrder] = useState("")
  const componentRef = useRef();
  const [copy, setCopy] = useState("Copiar")

  const handleCopy = (e) => {
    e.preventDefault()
    navigator.clipboard.writeText(window.location.href)
    setCopy("Copiado")
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const location = useLocation();
  const id = new URLSearchParams(location.search).get('id')
  const type = new URLSearchParams(location.search).get('type')

  const GetOrder = () => {
    (async () => {
      let irequestFilter = []
      irequestFilter.push({ 'key': 'id', 'value': `${id}` })
      irequestFilter.push({ 'key': 'type', 'value': `${type}` })
      let response = await GetAllWhtParams("common/order", irequestFilter)
      setOrder(response)
    })();
  };

  useEffect(() => {
    GetOrder()
  }, [])


  return (<> {order &&
    <Grid container justifyContent="center" mt={"32px"}>
      <Grid item xs={11} sx={{ display: "flex", gap: "8px" }} >
        <Button onClick={handlePrint} variant="contained" startIcon={<PrintIcon />}  >Imprimir</Button>

        <Tooltip title={copy} >
          <Button onClick={(e) => handleCopy(e)} variant="contained" startIcon={<ShareIcon />}  >
            Compartir</Button>
        </Tooltip>
      </Grid>
      {order && <PrinPass type={type} data={order} ref={componentRef} />}
    </Grid>
  }</>
  );
}

const PrinPass = React.forwardRef((props, ref) => {
  return (

    <Grid container justifyContent="center" ref={ref} mt="32px">
      <Grid item xs={11} >

        <HeaderOrder type={props.type} data={props.data} />
        <BodyOrder type={props.type} data={props.data} />

      </Grid>
    </Grid>
  );
});

export default ViewOrder; 