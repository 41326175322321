import React, {useState, useEffect} from 'react'
import MetaData from '../../components/landing/metaData';
import { useAuth } from "user-context-fran-dev";
import {BusinessMetaData, BusinessSettings} from '../../api/businessManages';
import Container from '../../layout/gridContainer';


const LandingMetaData = ({menu}) => {
    const [business, setBusiness] = useState("");
    const [styles, setStyles] = useState("");
    const { logout } = useAuth();
  
    useEffect(() => {
      UpdateBusinessInfo();
      UpdateStyles();
      window.localStorage.setItem("menu", menu);
    }, []);
  
    const UpdateBusinessInfo = () => {
      (async () => {
        let useBusines = new BusinessMetaData();
        let response = await useBusines.getAllById("1");
        setBusiness(response.data);
      })();
    };

    const UpdateStyles = () => {
      (async () => {
        let useBusines = new BusinessSettings();
        let response = await useBusines.getAllByIdAuth("1",logout);
        setStyles(response.data);
      })();
    };
    return ( 
        <Container> 
          {business && <MetaData data={business} update={UpdateBusinessInfo} updateStyles={UpdateStyles} styles={styles}/>}
        </Container>
     );
}
 
export default LandingMetaData;