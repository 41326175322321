import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import FormControl from "@mui/material/FormControl";
import { useAuth } from "user-context-fran-dev";
import { InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const GenericSearch = ({ Service, setData, label, setLoading }) => {

    const [searchString, setSearchString] = useState('');
    const handleSearchChange = (event) => {
        if (event.target.value.length >= 3 || event.target.value.length === 0) {
            (async () => {
                setSearchString(event.target.value);
                let irequestFilter = []
                irequestFilter.push({ 'key': 'pattern', 'value': `${event.target.value}` })
                let serviceInstance = new Service()
                let response = await serviceInstance.filter(irequestFilter, useAuth);
                setData(response.data)
            })();
        }
    };

    return (
        <>
            <FormControl
                fullWidth
                variant="standard"
                sx={{ /* m: 1, */ minWidth: 220 }}
                value={searchString}
            >

                <TextField /* id="standard-basic" */ label={label} variant="standard" size="small" onChange={handleSearchChange} />
            </FormControl>
        </>
    )
}

export default GenericSearch;


export const GenericSearchDrawer = ({ Service, setData, label, placeholder, setLoading }) => {
    const [searchString, setSearchString] = useState('');

    const handleSearchChange = (event) => {
        if (event.target.value.length >= 3 || event.target.value.length === 0) {
            (async () => {
                setSearchString(event.target.value);
                let irequestFilter = [];
                irequestFilter.push({ 'key': 'pattern', 'value': `${event.target.value}` });
                let serviceInstance = new Service();
                let response = await serviceInstance.filter(irequestFilter, useAuth);
                setData(response.data);
            })();
        }
    };

    return (
        <FormControl
            fullWidth
            variant="standard"
            sx={{ minWidth: 220 }}
            value={searchString}
        >
            <TextField
                fullWidth
                name="search"
                //variant="outlined"
                //label={label}
                placeholder={placeholder}
                onChange={handleSearchChange}
                sx={{ backgroundColor: "white", borderRadius: '5px' }}
                margin="dense"
                size="small"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </FormControl>
    )
}



export const GenericSearchNew = ({ params, handleChanges, label, name }) => {

    /*     const [searchString, setSearchString] = useState(''); 
        const handleSearchChange = (event) => {
            if( event.target.value.length >= 3 || event.target.value.length === 0){
                (async () => {
                setSearchString(event.target.value);
                let irequestFilter = []
                irequestFilter.push({'key':'pattern', 'value': `${event.target.value}`})
                let serviceInstance = new Service()
                let response = await serviceInstance.filter(irequestFilter);
                setData(response.data)
                })();
            }
        }; */

    return (
        <>
            <FormControl
                fullWidth
                name={name}
                variant="standard"
                sx={{ /* m: 1, */ minWidth: 220 }}
                value={params.get(name) || ""}
            >

                <TextField /* id="standard-basic" */ name={name} label={label} variant="standard" size="small" value={params.get("pattern") || ""} onChange={handleChanges} />
            </FormControl>
        </>
    )
}