import { BASE_PATH } from "../../utils/constants";
import { BaseService } from 'base-service-fran-dev';


export class ChangeWarehouseService extends BaseService {
    constructor() {
        const baseUrl = BASE_PATH;
        const endPoint = 'inventory/available/presentation/inventory';
        super(baseUrl, endPoint)
    }
}


export class ChangeWarehouseOnlineStore extends BaseService {
    constructor() {
        const baseUrl = BASE_PATH;
        const endPoint = 'store/order/change_wh';
        super(baseUrl, endPoint)
    }
}