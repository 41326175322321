import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IconButton, Paper, TextField, Typography, Grid, Button, InputAdornment, FormControl } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import '../scss/loginRecover.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuth } from 'user-context-fran-dev';
import { useLocation } from 'react-router-dom';
import { RecoverPass } from "../api/userLogin";
import { UseerrorOrsuccess, useNotification } from "../hooks/snackBarResponse";
import { useSnackbar } from 'notistack';
import Footer from "../components/Footer";
import SearchIcon from '@mui/icons-material/Search';
import { GenericSearchDrawer } from "../components/GenericSearch/GenericSearch";
import { ModalRight } from "../components/GenericsModals";
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import AdminBusinessManages from "../api/adminBusiness";
import * as context from 'user-context-fran-dev';


const SearchBusiness = ({ theme }) => {
    const [loading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState('');
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [msg, sendNotification] = useNotification();

    const { setBasePath, setReloadUser } = useAuth();


    const [openModal, setOpenModal] = useState(false);
    const [businessData, setBusinessData] = useState(null);


    const handleSearchChange = async (value) => {
        setSearchString(value);
        if (value.length >= 3 || value.length === 0) {
            let irequestFilter = [{ 'key': 'pattern', 'value': `${value}` }]; // /business?pattern=ukimil
            let serviceInstance = new AdminBusinessManages();
            let response = await serviceInstance.filter(irequestFilter);
            setBusinessData(response.data);
            setLoading(false);
        }
    };


    const handleOpenModal = () => {
        handleSearchChange(searchString);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const saveOnLocalStorage = (baseUrl) => {
        context.removeBaseUrl()
        setBasePath(`https://${baseUrl}/api/v1`)
        if (context.getBaseUrl()) {
            console.log(context.getBaseUrl())
            window.location.href = '/';
        }


    }


    return (
        <>
            <Box
                sx={{
                    px: 3,
                    bgcolor: "background.default",
                }}
                className={`box-container`}
            >
                <Paper
                    sx={{
                        boxShadow: 'none',
                        ...(theme === 'light' ?
                            { bgcolor: 'rgba(0,0,0,0)' } :
                            { bgcolor: 'rgba(0, 0, 0, 0)' })
                    }}
                    className="paperForm formSizeRecoverP"
                >
                    <Grid item className="grid-container">
                        <Grid item md={12} sx={{ paddingBottom: '7%' }}>
                            <Typography variant="h5" sx={{ color: "white" }}>
                                <strong>Configurar mi negocio en keskinube Panel</strong>
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <FormControl
                                fullWidth
                                variant="standard"
                                sx={{ minWidth: 220 }}
                                value={searchString}
                            >
                                <TextField
                                    fullWidth
                                    name="search"
                                    placeholder={'Ingresa el nombre de tu negocio'}
                                    onChange={(e) => handleSearchChange(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleOpenModal();
                                        }
                                    }}
                                    sx={{ backgroundColor: "white", borderRadius: '5px' }}
                                    margin="dense"
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item md={12} sx={{ marginTop: '15px' }}>
                            <LoadingButton
                                fullWidth
                                onClick={handleOpenModal}
                                loading={loading}
                                variant="contained"
                                sx={{ color: '#fff' }}
                                type="submit"
                            >
                                Buscar mi negocio
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <ModalRight
                open={openModal}
                setOpen={setOpenModal}
                title={<Typography variant="h6" sx={{ fontWeight: 'bold' }}>Resultados</Typography>}
                size="sm"
            >
                <Typography sx={{ fontSize: '16px', paddingTop: '5%', paddingBottom: '2%' }}>NEGOCIOS ENCONTRADOS PARA: "{searchString}"</Typography>
                {businessData && businessData.length > 0 ? (
                    businessData.map((business, index) => (
                        <div key={index} style={{
                            paddingBottom: '3%'
                        }}>
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '8px 16px',
                                    borderRadius: '8px',
                                    border: '2px solid rgba(0, 0, 0, 0.2)',
                                }}
                            >
                                <StorefrontOutlinedIcon sx={{ marginRight: '15px' }} />
                                <Typography variant="h6" sx={{ flexGrow: 1 }}>{business.name}</Typography>
                                <Button variant="text" onClick={() => { saveOnLocalStorage(business.api_domain) }}>Seleccionar</Button>
                            </Box>
                        </div>
                    ))
                ) : (
                    <Typography>No se encontraron resultados para "{searchString}"</Typography>
                )}
            </ModalRight>
            <Footer />
        </>
    );
}


export default SearchBusiness;