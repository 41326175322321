import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { TextField } from "@mui/material";
import BasicModal from "../../../components/GenericModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { initValAddModel, valSchAddModel } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import { ProductModel } from "../../../api/productModel";
import CountWords from "../../../components/CountWords";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from '@mui/material/Switch';
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import { useSnackbar } from 'notistack';


const AddModel = ({ mainModel, open, setOpen, article, getArticleInfo }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    return () => {
      formik.resetForm();
    }
  }, [open])

  const formik = useFormik({
    initialValues: initValAddModel(article),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchAddModel()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      console.log(formData)

      let currentName = article.name;

      if (article.isEdit && currentName !== formData.name) {  // Si estamos editando y el nombre ha cambiado, actualizar solo el nombre
        updateModel({ name: formData.name }, article.id);
      } else { // Si no estamos editando o el nombre no ha cambiado, seguir con el flujo normal
        if (article.isEdit) {
          updateModel(formData, article.id); // Actualizar el resto de los campos
        } else { // Agregar un nuevo artículo
          setModel(formData);
        }
      }

      getArticleInfo()
      resetForm()
      setOpen(false);
      setLoading(false);
    },
  });
  const setModel = async (formData) => {
    let setModel = new ProductModel();
    let response = await setModel.postDataAuth(formData, logout);

    getArticleInfo();


  }

  const updateModel = async (formData, id) => {
    let setModel = new ProductModel();
    let response = await setModel.editDataAuth(id, formData, logout);
    if (Number(response.status) < 300) {
      if (response.data?.message) {
        enqueueSnackbar(response.data?.message, { variant: "warning" })
      }
    }
    sendNotification(UseerrorOrsuccess(response))
    getArticleInfo();

  }



  return (
    <BasicModal open={open} setOpen={setOpen} title={article.isEdit ? "Editar modelo" : "Agregar nuevo modelo"}>
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={"8px"}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                label="Nombre "
                margin="dense"
                size="small"
                variant="outlined"
                helperText={formik.touched.name ? formik.errors.name : ""}
              />
              <CountWords length={formik.values.name.length} />

            </Grid>
            {article.isEdit && <Grid item xs={12}>
              <FormControlLabel
                disabled={article.isEdit && article.id === mainModel.main_model.id && formik.values.is_active}

                control={
                  <Switch
                    name="is_active"
                    checked={formik.values.is_active}
                    onChange={formik.handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={formik.values.is_active ? "Desactivar" : "Activar"}
              />
            </Grid>}
            {article.isEdit && article.id === mainModel.main_model.id && formik.values.is_active &&
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                  No es posible desactivar el modelo principal
                </Typography>
              </Grid>}


            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ marginTop: "15px" }}
                >
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    {article.isEdit ? "Editar" : "Agregar"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BasicModal>
  );
};

export default AddModel;
