import React, { useEffect, useState } from "react";
import "../../scss/adminBusiness.scss";
import { Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { Iniciales } from "../../utils/functions";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Container from "../../layout/gridContainer";
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import UserRemove from "../../api/userRemove";
import { useAuth } from "user-context-fran-dev";
import BranchManages from "../../api/branchManages";
import { BusinessGroups } from '../../api/businessGroups';
import CreateUserGroups from "../../api/createUserGroups";
import DeleteUserGroupfrom from "../../api/deleteUserGroup";
import UserManages from "../../api/userManages";
import UserBranch from "../../api/userBranch";
import { useNavigate } from "react-router-dom";
import { UseerrorOrsuccess, useNotification } from '../../hooks/snackBarResponse'
import { useParams } from "react-router-dom";
import { CardActionArea } from '@mui/material';

import HelpIcon from '@mui/icons-material/Help';
import { Box } from '@mui/system'
import { ModalRight } from "../../components/GenericsModals";
import { ConfirmAction } from "../../components/ConfirmAction/ConfirmAction";
//import catalogo_disabled from "../../assets/gifs/catalogo_dissabled.gif";

import WarehouseService from "../../api/warehouseService";



const UserDetails = ({ setReload }) => {

  const navigate = useNavigate();
  const { logout, auth } = useAuth();
  const [selectBranch, setSelectBranch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [table, setTable] = useState("");
  const [acces, setAcces] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [branch, setBranch] = useState("");
  const [msg, sendNotification] = useNotification();
  const { id } = useParams()
  console.log(auth)


  const addBranchService = new UserBranch();


  /*----------console.log(auth.user.pk!= user.id) ------------*/

  const [open, setOpen] = useState();
  const [confirmationData, setConfirmationData] = useState(null);

  const handleOpen = (id) => {
    setConfirmationData(id);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    //setOpen(false);
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  {/*} useEffect(() => {
    if (!open) {
      setAnchorEl(null);
    }
  }, [open]);*/}

  /*------------------------------- -------------------------*/



  const handleChangeBranch = (event) => {
    setSelectBranch(event.target.value);
    if (event.target.value != "") {
      addBranch(event.target.value);
      event = null;
    }
  };

  const addBranch = async (data) => {
    let response = await addBranchService.postDataAuth(
      {
        branch: data,
        user: currentUser.id
      }, logout);
    sendNotification(UseerrorOrsuccess(response))
    GetCurrentUser();

  }


  const handleDeleteUser = async (id, formData) => {
    let deleteUser = new UserRemove();
    let response = await deleteUser.editDataAuth(id, formData, logout);
    sendNotification(UseerrorOrsuccess(response))
    navigate(-1); //volver atras 

  }
  const UpdateBranchList = () => {
    (async () => {
      let getBranchs = new BranchManages();
      let response = await getBranchs.getAllAuth(logout);
      setTable(response.data);
    })();
  };

  const UpdateAppAcces = () => {
    (async () => {
      let getBranchs = new BusinessGroups();
      let response = await getBranchs.getAllAuth(logout);
      setAcces(response.data);
    })();
  };
  const GetCurrentUser = () => {
    (async () => {
      const getUser = new UserManages();
      /* console.log(id) */
      const response = await getUser.getAllByIdAuth(id, logout);
      setCurrentUser(response.data);
      setBranch(response.data.branch)
    })();
  };
  useEffect(() => {

    GetCurrentUser();
    UpdateBranchList();
    UpdateAppAcces();

    return () => {
      setReload((prev) => !prev);
      window.localStorage.setItem("menu", 1);
    };
  }, []);

  //For the ModalRight about "Accesos"
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };





  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //maxWidth: smallScreen ? '90%' : 600,
    width: '90%',
    bgcolor: '#ffffff',
    border: '3px solid #000',
    borderRadius: '3%',
    boxShadow: 24,
    p: '3em',
  };

  const accessInfo = [
    {
      type: 'Catálogo',
      explication: 'Al desactivar el botón del catálogo, se bloqueará automáticamente el acceso a la sección de agregar artículos y categorías.',
      //gif: `<img src="${catalogo_disabled}" alt="Descripción del GIF">`
    },
    {
      type: 'Inventario',
      explication: 'Al desactivar el botón del inventario, se bloqueará automáticamente el acceso a la gestion del inventario y sus ajustes.',
    },
    {
      type: 'Landing',
      explication: 'Al desactivar el botón de landing, se bloqueará automáticamente el acceso al creador del sitio web',
    },
    {
      type: 'Punto de venta',
      explication: 'Al desactivar el boton de punto de venta, se bloqueará automáticamente el acceso al punto de venta para abrir caja',
    },
    {
      type: 'Gestor de punto de venta',
      explication: 'Al desactivar el boton de punto de venta, se bloqueará automáticamente el acceso al gestor del punto de venta',
    },
    {
      type: 'Administración',
      explication: 'Al desactivar el boton de administración, se bloqueará automáticamente el acceso a la sección de administrador donde manejas usuarios, negocio, sucursales y sus permisos',
    },
    {
      type: 'Tienda',
      explication: 'Al desactivar el boton de gestor de tienda en linea, se bloqueará automáticamente el acceso al gestor de tienda en linea ',
    }
  ];

  return (
    <>
      {currentUser &&
        <Container>
          <Grid container>
            <Grid item xs={6} sm={6} md={11} xl={11}>
              <Typography
                variant="h4"
                className="title-card"
                gutterBottom
                component="div"
              >
                <strong>Perfil</strong>
              </Typography>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={12} md={6} xl={6} order={{ xs: 2, md: 1 }}>
                <Typography variant="h7" gutterBottom component="div">
                  <Box>
                    <Typography variant="h6" noWrap>
                      Accesos
                      <IconButton onClick={handleOpenModal}>
                        <HelpIcon />
                      </IconButton>
                    </Typography>
                    <ModalRight
                      title="Funciones de cada acceso"
                      size="xs"
                      open={openModal}
                      setOpen={setOpenModal}
                    >
                      <>
                        <Box sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                          <div>
                            <br />
                            {accessInfo.map((access) => (
                              <div key={access.type}>
                                <h4><strong>{access.type}</strong></h4>
                                <p>{access.explication}</p>
                                <br />
                              </div>
                            ))}
                          </div>
                        </Box>
                      </>
                    </ModalRight>

                  </Box>
                </Typography>
                {/* <Typography variant="caption" gutterBottom component="div">
            {(auth.user.pk== currentUser.id)? "No puedes modificar tus propios accesos":""}
          </Typography> */}


                {acces && currentUser &&
                  <FormControl component="fieldset" variant="standard">
                    {acces.map((data) => (
                      /* data.id!=7 && */
                      <Switches data={data} user={currentUser} key={data.id} />
                    ))}
                  </FormControl>
                }

                <Typography variant="h7" gutterBottom component="div" mt={"32px"}>
                  <strong>Asignar acceso a sucursales</strong>
                </Typography>
                {table &&
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 220, }}>
                    <InputLabel id="demo-simple-select-filled-label">
                      Sucursal
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={selectBranch}
                      onChange={handleChangeBranch}
                    >
                      <MenuItem value="">
                        <em>Surcursal</em>
                      </MenuItem>
                      {table.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
                <Grid item xs={12} sm={10} md={6}>


                  {branch &&
                    <List>

                      {confirmationData && <ConfirmAction
                        open={open}
                        handleOpen={handleOpen}
                        handleClose={handleClose}
                        title={`Eliminar sucursal ${confirmationData.branch.name}?`}
                        content={`¿Estás seguro que deseas eliminar la sucursal ${confirmationData.branch.name}?`}
                        disagreeText="Cancelar"
                        agreeText="Eliminar"
                        service={addBranchService}
                        id={confirmationData.id}
                        getCurrent={GetCurrentUser}
                      />}
                      {branch.map((data, index) => (
                        <div key={index}>
                          <ListItem>
                            <ListItemText primary={data.branch.name} />
                            <Link
                              edge="end"
                              component="button"
                              variant="body2"
                              underline="none"
                              onClick={() => handleOpen(data)}
                            >
                              Eliminar
                            </Link>
                          </ListItem>
                          <Divider />
                        </div>
                      ))}
                    </List>
                  }




                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={6} xl={6} order={{ xs: 1, md: 2 }}>
                <Grid container justifyContent="flex-end">
                  <IconButton onClick={handleClick} size="large">
                    <MoreHorizIcon
                      fontSize="inherit"
                      sx={{ color: "text.secondary" }}
                    />
                  </IconButton>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    autoFocus={false}
                  >
                    <MenuItem onClick={() => { handleDeleteUser(id, ""); handleClose(); }}>Eliminar</MenuItem>
                  </Menu>
                </Grid>
                <Grid container justifyContent="flex-end">
                  <Card sx={{ maxWidth: 345, minWidth: 250 }}>
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe">
                          {Iniciales(currentUser.first_name, currentUser.last_name)}
                        </Avatar>
                      }
                      title={`${currentUser.first_name} ${currentUser.last_name}`}
                      subheader={<> {currentUser.email}          <Typography variant="subtitle1" component="div" sx={{ color: "text.green" }}>
                        {currentUser.is_superuser ? "Super User" : (currentUser.is_staff ? "Staff" : "")}
                      </Typography></>}
                    />

                    <Divider />
                    {(auth.user.pk == id || auth.user.is_superuser) &&
                      <>
                        <CardActionArea onClick={() => { navigate("/changes-password") }}>
                          <Typography variant="subtitle1" component="div" sx={{ display: "flex", justifyContent: "start", padding: "4px 16px" }}>
                            Cambiar contraseña
                          </Typography>
                        </CardActionArea>
                        <CardActionArea onClick={() => { }}>
                          <Typography variant="subtitle1" component="div" sx={{ display: "flex", justifyContent: "start", padding: "4px 16px" }}>
                            Subir foto de perfil
                          </Typography>
                        </CardActionArea>
                      </>}
                  </Card>
                </Grid>
              </Grid>

            </Grid>

          </Grid>
        </Container>
      }
    </>
  );
};

const Switches = ({ data, user }) => {
  const [state, setState] = useState(false);
  const [hidden, setHidden] = useState(true);
  const { logout, auth } = useAuth();


  useEffect(() => {

    if (user?.groups) {

      /* console.log(user) */
      const state = (user.groups.find((group) => data.name === group.name)) !== undefined ? true : false;
      /* console.log(state) */
      /* setHidden(auth.user.pk== user.id) */
      setHidden(user.is_superuser)
      setState(state)
    }
  }, []);

  const SetGroup = async () => {

    console.log("hola")
    let setGroup = new CreateUserGroups();
    let response = await setGroup.postDataAuth({
      group: data.name,
      user: user.id
    }, logout);

  };
  const DeleteGroup = async () => {
    console.log(user.id)
    let deleteGroup = new DeleteUserGroupfrom();
    let response = await deleteGroup.postDataAuth({
      group: data.name,
      user: user.id
    }, logout);

  };

  const handleChanges = () => {

    if (!state) {
      SetGroup();


    } else {
      console.log("apagado")
      DeleteGroup();

    }
    setState(!state)
  }

  const list = ["Administración", "Landing", "Catálogo", "Tienda", "Inventario", "Punto de venta", "Gestor de punto de venta"]
  return (
    <FormControlLabel
      className="switch"
      disabled={hidden}
      control={
        <Switch
          checked={state}
          onChange={() => { handleChanges() }}
          name=""
        />
      }
      label={list[data.id - 1]  /* +" "+ data.id+" "+data.name.charAt(0).toUpperCase() + data.name.slice(1) */}
    />
  );
};

export default UserDetails;
