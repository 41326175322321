import { Alert, Button, CardHeader, Grid, InputAdornment, Menu, MenuItem, Paper, TableCell, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BasicTable from '../../../components/GenericTable'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BasicModal from '../../../components/GenericModal';
import ImageSize from '../../../components/ImageSize';
import GenericTable from "../../../components/GenericTable"
import { BottonModalDiag, ModalRight } from '../../../components/GenericsModals';
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValStoreQuote, valSchStoreQuote } from '../../../utils/formHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Box } from '@mui/system';
import { StoreGuide, StoreQuotation } from '../../../api/store/storeOrders';
import { UseerrorOrsuccess, useNotification } from "../../../hooks/snackBarResponse"
import { useAuth } from "user-context-fran-dev";
import nueveMinutos from "../../../assets/shippingCompanyIcons/99MINUTOS.png"
import ampm from "../../../assets/shippingCompanyIcons/AMPM.png"
import defaultImg from "../../../assets/shippingCompanyIcons/DEFAULT.png"
import JTEXPRESS from "../../../assets/shippingCompanyIcons/JTEXPRESS.png"
import DHL from "../../../assets/shippingCompanyIcons/DHL.png"
import ESTAFETA from "../../../assets/shippingCompanyIcons/ESTAFETA.png"
import FEDEX from "../../../assets/shippingCompanyIcons/FEDEX.png"
import FLECHAAMARILLA from "../../../assets/shippingCompanyIcons/FLECHA_AMARILLA.png"
import IVOY from "../../../assets/shippingCompanyIcons/IVOY.png"
import PAQUETEEXPRESS from "../../../assets/shippingCompanyIcons/PAQUETEEXPRESS.png"
import PILOT from "../../../assets/shippingCompanyIcons/PILOT.png"
import REDPACK from "../../../assets/shippingCompanyIcons/REDPACK.png"
import TRESGUERRAS from "../../../assets/shippingCompanyIcons/TRESGUERRAS.png"
import UPS from "../../../assets/shippingCompanyIcons/UPS.png"
import AddressManages from '../../../api/addressManages';
import { FormHelperText } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import Checkbox from "@mui/material/Checkbox";
import { useSnackbar } from 'notistack';
import { formatMoney } from '../../../utils/functions';
import { Link } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AlertBox from '../../../components/AlertBox/AlertBox';
import { ChangeWarehouseOnlineStore, ChangeWarehouseService } from '../../../api/pos/orderConfirm';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

const serviceImages = (service) => {

  switch (service) {
    case "99MINUTOS":
      return nueveMinutos;
    /* break; */
    case "AMPM":
      return ampm;

    case "JTEXPRESS":
      return JTEXPRESS;

    case "DHL":
      return DHL;

    case "ESTAFETA":
      return ESTAFETA;

    case "FEDEX":
      return FEDEX;

    case "FLECHAAMARILLA":
      return FLECHAAMARILLA;

    case "IVOY":
      return IVOY;

    case "PAQUETEEXPRESS":
      return PAQUETEEXPRESS;

    case "PILOT":
      return PILOT;

    case "REDPACK":
      return REDPACK;

    case "TRESGUERRAS":
      return TRESGUERRAS;

    case "UPS":
      return UPS;

    default:
      return defaultImg;

  }

}

export const ButtonStatus = ({ userDetails, orderDetails, statusOrder, cancelOrder, NextStep, update }) => {
  //Estados para los modales
  const Navigate = useNavigate();

  const [availableB, setAvailableB] = useState(false);
  const [dataAv, setDataAv] = useState("")

  const [quote, setQuote] = useState(false)


  const [service, setService] = useState(false);
  const [dataService, setDataService] = useState("");

  //estados y funciones para otras cosas
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { enqueueSnackbar } = useSnackbar();

  const [checked, setChecked] = useState();
  const [listGuia, setListGuia] = useState();

  const [loading, setLoading] = useState(false);

  const [missing, setMissing] = useState()

  /* const [originAddress, setOriginAddress] = useState(false); */

  const handleChangeCheck = (event, data) => {
    /*  setChecked({...checked, [event.target.name]: {...data, check: event.target.checked}});
     console.log(checked) */
    setChecked({ ...checked, [event.target.name]: { ...data, check: event.target.checked } });

  };

  useEffect(() => {
    if (userDetails.status === 4) {
      let items = []
      userDetails?.cart?.cart_items.forEach((element) => {
        items.push({ ...element, check: true })
      })
      let list = userDetails?.cart?.cart_items.filter(x => x.status === 3);

      /* console.log("list lenght", list.length)
      console.log("all list lenght", userDetails?.cart?.cart_items.length) */

      if (list.length === userDetails?.cart?.cart_items.length) {  /* TODO: checar si funciona */
        setMissing(false)
      } else {
        setMissing(true)
      }

      /* console.log("list items:",items) */
      setChecked(items)
    }
  }, [userDetails])


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  //Funciones para los modales
  const OpenModalAvaBranch = (data) => {
    setAvailableB(true)
    setDataAv(data)
  }
  const OpenModalQuote = (data) => {
    console.log(checked)
    let list = Object.values(checked).filter(x => x.check == true && x.status == 3)

    console.log(list)
    if (list != "") {
      setListGuia(list)
      setQuote(true)
      handleClose()
    } else {
      enqueueSnackbar("Faltan seleccionar artículos", {
        variant: "error",
        //autoHideDuration: 1000,
        //action
      });
    }


    /*   setDataQuote(data) */
  }
  const OpenService = (data, modalInfo, originAddress) => {
    setService(true)
    setDataService({ ...data, "modal_info": { ...modalInfo }, "originAddress": { ...originAddress } })
  }

  const [openModalOrder, setOpenModalOrder] = useState(false);
  const [openModalOrderCancel, setOpenModalOrderCancel] = useState(false);

  //--------Para confirmar
  const handleOpenModalConfirm = () => {
    setOpenModalOrder(true);
  };
  const handleCloseModalConfirm = () => {
    setOpenModalOrder(false);
  };


  //-------Para cancelar
  const handleOpenModalCancel = () => {
    setOpenModalOrderCancel(true);
  };

  const handleCloseModalCancel = () => {
    setOpenModalOrderCancel(false);
  };
  console.log('Status del pedido: ', userDetails.status);
  console.log('Status del cancel: ', userDetails.cancel?.status);
  console.log('Numero de cancelaciones por tienda: ', userDetails?.cancel?.canceled_by === 2);


  return (
    <>
      <ModalAvailableBranch openModal={availableB} setOpenModal={setAvailableB} data={dataAv} userDetails={userDetails} />
      <ModalQuote openModal={quote} setOpenModal={setQuote} data={userDetails} OpenService={OpenService} />
      <ModalService openModal={service} setOpenModal={setService} data={dataService} orderData={userDetails} listGuia={listGuia} update={update} />

      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        {/*----Boton para Cancelar---- */}
        {(userDetails?.status === 3 || userDetails?.status === 4) && !userDetails?.cancel?.status ? (
          <>
            <LoadingButton
              loading={loading} variant="outlined" size="medium" disableElevation sx={{ mr: '20px' }} onClick={handleOpenModalCancel}>Cancelar</LoadingButton>
            <ModalRight
              open={openModalOrderCancel}
              setOpen={setOpenModalOrderCancel}
              title={<strong>Cancelar orden</strong>}
              size="xs"
            >
              <AlertBox
                text="Al cancelar la orden en automatico el cargo se reembolsara a travez de CLIP, si cuentas con los fondos suficientes, el reembolso se aplica de manera inmediata."
                color="102, 60, 0"
                colorIcon="102, 57, 1"
                colorText="102, 57, 1"
              />
              <Grid container>
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 50,
                    left: 120,
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{ width: 'auto', marginRight: '10px' }} // Utiliza 'auto' para ajustar el ancho al contenido
                    onClick={handleCloseModalCancel}
                  >
                    Mas tarde
                  </Button>
                  <LoadingButton
                    variant="contained"
                    sx={{ width: 'auto' }}
                    onClick={() => cancelOrder(setLoading)}
                    loading={loading}
                  >
                    Cancelar ahora
                  </LoadingButton>
                </Box>
              </Grid>
            </ModalRight>
          </>
        ) : null}

        {/*------Boton para Reembolsar----- */}
        {(userDetails.status === 3 || userDetails.status === 4) && userDetails.cancel?.status === 1 ? (
          <>
            <Button
              variant="contained"
              //onClick={handleClick}
              loading={loading}
              disableElevation
              sx={{ mr: '20px' }}
              onClick={handleOpenModalConfirm} // Abre el ModalRight de reembolso
              size="medium"
            >
              Reembolsar
            </Button>

            <ModalRight
              open={openModalOrder}
              setOpen={setOpenModalOrder}
              title={<strong>Reembolsar orden</strong>}
              size="xs"
            >
              <AlertBox
                text="El reembolso se realiza a través de CLIP. Si cuentas con los fondos suficientes, el reembolso se aplica de manera inmediata."
                color="102, 60, 0"
                colorIcon="102, 57, 1"
                colorText="102, 57, 1"
              />
              <Grid container>
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 50,
                    left: 170,
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{ width: 'auto', marginRight: '10px' }} // Utiliza 'auto' para ajustar el ancho al contenido
                    onClick={handleCloseModalConfirm}
                  >
                    Mas tarde
                  </Button>
                  <LoadingButton
                    variant="contained"
                    sx={{ width: 'auto' }} // Utiliza 'auto' para ajustar el ancho al contenido
                    onClick={() => cancelOrder(setLoading)}
                    loading={loading}
                  >
                    Confirmar
                  </LoadingButton>
                </Box>
              </Grid>
            </ModalRight>
          </>
        ) : (
          // No mostrar el botón de "Reembolsar"
          null
        )}

        {/*-------Boton Confirmar------- */}
        {userDetails.status === 3 && !userDetails.cancel?.status ? (
          <>
            <LoadingButton loading={loading} variant="contained" size="medium" onClick={handleOpenModalConfirm} >Confirmar</LoadingButton>
            <ModalRight
              open={openModalOrder}
              setOpen={setOpenModalOrder}
              //data={data} // Aquí pasas el elemento actual de userDetails?.cart?.cart_items
              title={<strong>Confirmar</strong>}
              size="xs"
            >
              <AlertBox
                text="Al continuar, estás de acuerdo con que cuentas con el inventario disponible para atender la orden.
            Si el artículo está en exhibición, recomendamos apartarlo a la brevedad para evitar cancelaciones por falta de inventario."
                color="102, 60, 0"
                colorIcon="102, 57, 1"
                colorText="102, 57, 1"
              />
              <Grid container >
                <Grid sx={{ position: 'absolute', bottom: 50, left: 200 }}>
                  <Button variant="outlined" sx={{ width: '45%', marginRight: '13px' }} onClick={handleCloseModalConfirm}>Cancelar</Button>
                  <LoadingButton variant="contained" sx={{ width: '45%' }} onClick={() => NextStep({ status: 4 }, setLoading)} loading={loading}>Confirmar</LoadingButton>
                </Grid>
              </Grid>
            </ModalRight>
          </>
        ) : (
          // No mostrar el botón de "Reembolsar"
          null
        )}

        {/*-------Boton para enviar------*/}

        {userDetails.status == 4 && !userDetails.cancel?.status ? (
          <>
            <div style={{ display: "inline-block" }}>
              <Button
                loading={loading}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                size="medium"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="contained"
              >
                Enviar
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={OpenModalQuote}>Con guía en Envioclick</MenuItem>
                {/*<MenuItem onClick={handleClose}>Con mis guías</MenuItem>*/}
              </Menu>
            </div>
          </>
        ) : (
          // No mostrar el botón de "Reembolsar"
          null
        )}


      </Grid>
    </>
  );
};

export const Order = ({ userDetails, orderDetails, statusOrder, cancelOrder, NextStep, update }) => {

  const Navigate = useNavigate();

  //Estados para los modales
  const [availableB, setAvailableB] = useState(false);
  const [dataAv, setDataAv] = useState("")

  const [quote, setQuote] = useState(false)


  const [service, setService] = useState(false);
  const [dataService, setDataService] = useState("");

  //estados y funciones para otras cosas
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [checked, setChecked] = useState();
  const [listGuia, setListGuia] = useState();

  const [loading, setLoading] = useState(false);

  const [missing, setMissing] = useState()

  const handleChangeCheck = (event, data) => {
    /*  setChecked({...checked, [event.target.name]: {...data, check: event.target.checked}});
     console.log(checked) */
    setChecked({ ...checked, [event.target.name]: { ...data, check: event.target.checked } });

  };

  useEffect(() => {
    if (userDetails.status === 4) {
      let items = []
      userDetails?.cart?.cart_items.forEach((element) => {
        items.push({ ...element, check: true })
      })
      let list = userDetails?.cart?.cart_items.filter(x => x.status === 3);

      /* console.log("list lenght", list.length)
      console.log("all list lenght", userDetails?.cart?.cart_items.length) */

      if (list.length === userDetails?.cart?.cart_items.length) {  /* TODO: checar si funciona */
        setMissing(false)
      } else {
        setMissing(true)
      }

      /* console.log("list items:",items) */
      setChecked(items)
    }
  }, [userDetails])


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  //Funciones para los modales
  const OpenModalAvaBranch = (data) => {
    setAvailableB(true)
    setDataAv(data)
  }

  const OpenModalQuote = (data) => {
    console.log(checked)
    let list = Object.values(checked).filter(x => x.check == true && x.status == 3)

    console.log(list)
    if (list != "") {
      setListGuia(list)
      setQuote(true)
      handleClose()
    } else {
      enqueueSnackbar("Faltan seleccionar artículos", {
        variant: "error",
        //autoHideDuration: 1000,
        //action
      });
    }

    /*   setDataQuote(data) */
  }
  const OpenService = (data, modalInfo, originAddress) => {
    setService(true)
    setDataService({ ...data, "modal_info": { ...modalInfo }, "originAddress": { ...originAddress } })
  }

  const { id } = useParams();
  const { logout, auth } = useAuth();

  const [dataWarehouse, setDataWarehouse] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemModelId, setSelectedItemModelId] = useState(null);
  const [selectedItemPresentationId, setSelectedItemPresentationId] = useState(null);

  const getWarehouseService = async (modelId, presentationId) => {
    let irequestFilter = [];
    irequestFilter.push({ key: 'model', value: modelId });
    irequestFilter.push({ key: 'pres', value: presentationId });
    let getWarehouse = new ChangeWarehouseService();
    let response = await getWarehouse.filter(irequestFilter);
    console.log('Almacenes disponibles:', response.data);
    setDataWarehouse(response.data);
  };


  useEffect(() => {
    if (selectedItemId) {
      getWarehouseService(selectedItemModelId, selectedItemPresentationId);
    }
  }, [selectedItemId]); // Solo se ejecutará cuando selectedItemId cambie


  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const handleCheckboxChange = (orderId, itemId, warehouseId) => {
    setSelectedWarehouse(warehouseId);
    console.log(` Order ID: ${orderId}, Item ID: ${itemId}, Warehouse ID: ${warehouseId}  `);
  };


  const changeWarehouse = async (order_id, item_id, available_id) => {
    const formData = {
      order_id,
      item_id,
      available_id
    };
    console.log('Datos recibidos en changeWarehouse:', formData);

    setLoading(true);
    let updateWarehouse = new ChangeWarehouseOnlineStore();
    let response = await updateWarehouse.postDataAuth(formData, logout);
    sendNotification(UseerrorOrsuccess(response));
    setLoading(false);

    // Refrescar almacenes después de cambiar el almacén
    getWarehouseService(selectedItemModelId, selectedItemPresentationId);
    update();
  };

  /*-----------------------------------------------------------------*/
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (data) => {
    console.log("ESTA INFO ME TRAE", data);
    console.log("Modelo nombre:", data?.model?.name);
    console.log("Modelo:", data?.model?.id);
    console.log("Presentation: ", data?.presentation?.id);

    setSelectedItemId(data.id);
    setSelectedItemModelId(data?.model?.id);
    setSelectedItemPresentationId(data?.presentation?.id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    console.log("selectedItemId actualizado:", selectedItemId);
  }, [selectedItemId]);


  /*-----------------------------------------------------------------*/
  const [openModalOrder, setOpenModalOrder] = useState(false);

  const handleOpenModalConfirm = () => {
    setOpenModalOrder(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalOrder(false);
  };

  const handleClickInventory = () => {

    Navigate("/inventory/inventory/tab=adjustment&page?tab=adjustment&page=1");

  };

  return (



    <>
      <ModalAvailableBranch openModal={availableB} setOpenModal={setAvailableB} data={dataAv} userDetails={userDetails} />
      <ModalQuote openModal={quote} setOpenModal={setQuote} data={userDetails} OpenService={OpenService} />
      <ModalService openModal={service} setOpenModal={setService} data={dataService} orderData={userDetails} listGuia={listGuia} update={update} />


      <Grid container marginTop='30px'>
        <Grid item xs={12} sx={{ display: "flex", gap: "8px", justifyContent: "flex-start", flexWrap: "wrap" }}>
          {userDetails.status === 5 && <Link
            style={{
              textDecoration: "none"
            }}
            to={`/order?id=${userDetails.id}&order_no=${userDetails.cart.id}&type=order_store`
            }
            target={"_blank"}
          >
            <Button variant="outlined" size="medium" disableElevation><PictureAsPdfIcon sx={{ mr: '10px' }} />ver orden</Button>
          </Link>}
          {userDetails.status === 5 && <a href={userDetails.shipping_guide[0].vendor_guide_url} target="_blank" rel='noreferrer'>
            <Button variant="outlined" size="medium" disableElevation><PictureAsPdfIcon sx={{ mr: '10px' }} />ver guía</Button>
          </a>}
        </Grid>


        {/* ESTOS ESTADOS ES LO MISMO QUE UTILIZO EN LA FUNCION CONST BUTTONSTATUS 

        {userDetails.status == 3 &&

          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={loading} variant="outlined" size="medium" disableElevation sx={{ mr: '20px' }} onClick={() => cancelOrder(setLoading)}>Cancelar</LoadingButton>
            <LoadingButton
              loading={loading} variant="contained" size="medium" onClick={() => NextStep({ status: 4 }, setLoading)}  >Confirmar</LoadingButton>
          </Grid>
        }


        {/*ESTATUS DE ENVIAR EL PEDIDO* /}

        {userDetails.status == 4 &&
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={loading} variant="outlined" size="medium" disableElevation sx={{ mr: '20px' }} onClick={() => cancelOrder(setLoading)}>
              Cancelar
            </LoadingButton>
            <div style={{ display: "inline-block" }}>
              <Button
                loading={loading}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                size="medium"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="contained"
              >
                Enviar
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={OpenModalQuote}>Con guía en Envioclick</MenuItem>
                {/*<MenuItem onClick={handleClose}>Con mis guías</MenuItem>* /}

              </Menu>
            </div>
          </Grid>
        }

        {/*FALTA DECLARAR LA MODALDRAWER PARA MOSTRAR DENTRO ESTO * /}
        {/*ESTATUS 7 PARA REEMBOLSAR* /}
        {userDetails.status === 7 && userDetails.cancel.receipt_no !== null &&
          <Grid item xs={3.8} sm={2} md={1.7} lg={1} xl={1} sx={{ display: "flex", justifyContent: "flex-end" }} >
            <Button
              variant="contained"
              //onClick={handleClick}
              loading={loading}
              disableElevation
              sx={{ mr: '20px' }}
              onClick={() => cancelOrder(setLoading)}
              size="medium"
            >
              Reembolsar
            </Button>
          </Grid>
        }

      */}




      </Grid>
      <Grid item xs={12} sx={{ margin: "16px 0" }}>
        {missing && <Alert sx={{ bgcolor: "error.alert", color: "text.primary" }} severity="error">{`No se cuenta con el inventario disponible en el  – revisa la materia prima faltante por receta!`}</Alert>}
      </Grid>

      <Grid container marginBottom='20px'>
        <Grid item xs={12} sm={6} sx={{ paddingBottom: '5%' }}>
          <Paper sx={{ padding: "8px 16px", backgroundColor: 'background.header', maxWidth: '95%' }}>
            <Typography variant='h6' fontWeight='bold'>Datos del Cliente</Typography>
          </Paper>
          <Typography variant='body2' marginTop='15px'>Nombre: <b>{userDetails.user?.first_name} {userDetails.user?.last_name} </b></Typography>
          <Typography variant='body2' marginTop='10px'>Email:<b> {userDetails.user?.email} </b></Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper sx={{ padding: "8px 16px", backgroundColor: 'background.header', maxWidth: '95%' }}>
            <Typography variant='h6' fontWeight='bold'> Dirección de envíos</Typography>
          </Paper>
          <Typography variant='body2' marginTop='10px'>
            {userDetails.delivery_address?.street} #{userDetails.delivery_address?.ext_number}. {userDetails.delivery_address?.suburb}, {userDetails.delivery_address?.postal_code}. {userDetails.delivery_address?.city}, {userDetails.delivery_address?.state}
          </Typography>
          <Typography variant='body2' marginTop='15px'>Receptor: <b>{userDetails.delivery_address?.recipient_first_name} {userDetails.delivery_address?.recipient_last_name} </b></Typography>
          <Typography variant='body2' marginTop='10px'>Teléfono:<b> {userDetails.delivery_address?.phone} </b></Typography>
        </Grid>
        <Grid item xs={3} >
          {/*  <Typography variant='h6' fontWeight='bold'> Facturación </Typography> */}
        </Grid>
      </Grid>

      <Box sx={{ paddingBottom: '2%' }}>
        <Paper sx={{ padding: "8px 16px ", backgroundColor: 'background.header', maxWidth: '100%' }}>
          <Typography variant='h6' fontWeight='bold'> Artículos</Typography>
        </Paper>
      </Box>

      {userDetails?.cart?.cart_items && <BasicTable titles={
        userDetails.status === 3 ? [
          "Imagen",
          "Artículo / Modelo y presentación",
          "Cantidad",
          "Precio",
          "Importe",
          "Sucursal de envío"
        ] : userDetails.status === 4 ? [
          "Estado de envío",
          "Imagen",
          "Artículo / Modelo y presentación",
          "Cantidad",
          "Precio",
          "Importe",
          "Sucursal de envío"

        ] :
          [
            "Imagen",
            "Artículo / Modelo y presentación",
            "Cantidad",
            "Precio",
            "Importe",
            "Sucursal de envío"
          ]
      } >



        {orderDetails && userDetails?.cart?.cart_items.map((data, index) => {
          return (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              hover
            >
              {userDetails.status === 4 && checked && <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw", paddingLeft: "30px" }}>
                {checked[index].status === 4 && <Typography variant="subtitle2" component="div"> Enviado </Typography>}
                {checked[index].status === 3 && <Checkbox checked={checked[index].check} name={index} onChange={(e) => handleChangeCheck(e, checked[index])} />}

              </TableCell>}

              <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw", paddingLeft: "30px" }}>
                <img src={data?.model?.image?.url} alt={data?.model?.name} style={{ maxWidth: '45px' }} />
              </TableCell>

              {/*ARTICULO / MODELO Y PRESENTACION */}
              <TableCell sx={{ maxWidth: '250px' }} align="left">
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <CardHeader
                    sx={{ maring: 0, padding: 0 }}
                    title={
                      <Typography variant="subtitle2" component="div">
                        {data?.model?.product?.name}
                        {/* {data.available?.model?.product?.name} */}
                      </Typography>}
                    subheader={<Typography variant="subtitle2" component="div">
                      {data?.model?.name}, {data.presentation.name}
                      {/* {data.available?.model?.name}, {data.presentation.name}    */}
                    </Typography>}
                  />
                  {/* {data.available?.model.product?.name}  / {data.available?.model?.name}, {data.presentation} */}
                </Typography>
              </TableCell>

              <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw", paddingLeft: "30px" }}>
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {data?.quantity}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {formatMoney(data.temp_breakdown.price)}
                </Typography>
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textDecoration: 'line-through' }}>
                  {formatMoney(data.temp_price)}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {formatMoney(data?.temp_breakdown.total)}
                </Typography>
              </TableCell>

              <TableCell align="left">
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {data?.inventory_transaction?.available[0]?.warehouse?.name}
                </Typography>
                {data?.inventory_transaction?.qty < 1 && userDetails.status === 3 && (
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: 'red',
                    }}
                  >
                    No Stock
                  </Typography>
                )}

                {userDetails.status === 3 && userDetails?.cancel?.canceled_by !== 1 && (
                  <Typography variant="subtitle2" component="div" sx={{ textAlign: 'left' }}>
                    <Button onClick={() => handleOpenModal(data)}>Cambiar</Button>
                  </Typography>
                )}

              </TableCell>

              {/*  {userDetails.status == 3 && <TableCell align="center">
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Button onClick={handleOpenModal}>Editar</Button>
                </Typography>
              </TableCell>}*/}

            </TableRow>
          );

        })}


      </BasicTable>}

      <Grid container justifyContent='right' textAlign='right' marginTop='20px' marginBottom="40px">

        <Grid item xs={2} color='gray' >
          <Typography>Subtotal:</Typography>
          <Typography marginTop='10px'> Descuento:</Typography>
          <Typography marginTop='10px'>Envío:</Typography>
          <Typography marginTop='10px' fontWeight='bold'>Total:</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{formatMoney(userDetails.sub_total)}</Typography>
          <Typography marginTop='10px'>{formatMoney(userDetails.discount)}</Typography>
          <Typography marginTop='10px'>{formatMoney(userDetails.shipping_cost)}</Typography>
          <Typography marginTop='10px'>{formatMoney(userDetails.total)}</Typography>
        </Grid>
      </Grid>

      {userDetails?.cart?.cart_items.map((data, index) => (
        <ModalRight
          key={index}
          open={openModal && selectedItemId === data.id}
          setOpen={setOpenModal}
          //data={data} // Aquí pasas el elemento actual de userDetails?.cart?.cart_items
          title={<strong>Cambiar sucursal de envío</strong>}
          size="xs"
        >
          <Grid container justifyContent="space-around" alignItems="center" sx={{ paddingTop: '24px' }}>
            <Grid item xs={12}>
              <Grid container>
                {data && (
                  <>
                    <Grid xs={2}>
                      <ImageSize img={data?.model?.image?.url} weight={2} />
                    </Grid>
                    <Grid xs={8} ml={{ xs: "8px", md: "22px" }}>
                      <Typography sx={{ fontWeight: 'bold' }} >{data?.model?.product?.name}</Typography>
                      <Typography sx={{ fontSize: '15px' }} >{data?.model?.name}, {data.presentation.name}</Typography>
                      <Typography sx={{ fontSize: '15px', color: 'green', fontWeight: 'bold' }} display="inline">{formatMoney(data.temp_breakdown.price)} {data.discount !== null && (
                        <Typography sx={{ color: 'gray', textDecoration: "line-through"}} display="inline">{formatMoney(data.temp_price)}</Typography>
                        )}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {data &&
                <Typography sx={{ fontSize: '15px' }} >
                  SKU: <span style={{ fontWeight: 'bold' }}>{data?.model?.product?.sku ? data.model.product.sku : 'N/A'}</span>
                </Typography>
              }
              {data &&
                <Typography sx={{ fontSize: '15px' }} >
                  Código de barras: <span style={{ fontWeight: 'bold' }}>{data?.presentation?.barcode ? data.presentation.barcode : 'N/A'}</span>
                </Typography>
              }
              {data &&
                <Typography sx={{ fontSize: '15px' }} >
                  Cantidad: <span style={{ fontWeight: 'bold' }}>{data?.quantity}</span>
                </Typography>
              }
            </Grid>

            <Grid item xs={12} sx={{ paddingTop: '8%' }}>
              <Typography sx={{ fontWeight: 'bold', paddingBottom: '5%' }} >Almacén actual de salida:</Typography>
              <div style={{ paddingBottom: '3%' }}>
                <Box sx={{
                  padding: '8px 16px',
                  borderRadius: '8px',
                  border: '2px solid rgba(0, 0, 0, 0.2)',
                }}>
                  {data &&
                    <Box sx={{ display: 'flex', }}>
                      <Typography sx={{ flexGrow: 1, fontWeight: 'bold' }}>{data?.inventory_transaction?.available[0]?.warehouse?.name}</Typography>
                    </Box>
                  }
                  <Typography variant="subtitle2" sx={{ marginTop: '4px' }}>
                    Disponible: <span style={{ fontWeight: 'bold' }}>{data?.inventory_transaction?.available[0]?.quantity}</span>
                  </Typography>
                </Box>
              </div>
            </Grid>

            <Grid item xs={12} sx={{ paddingTop: '8%' }}>
              <Typography sx={{ fontWeight: 'bold', paddingBottom: '5%' }} >Disponible en otros almacenes:</Typography>
              {dataWarehouse?.map((warehouse, index) => (
                <div key={index}>
                  {warehouse.items.map((item, itemIndex) => {
                    // Verificar si el almacén actual es igual al almacén que estamos iterando
                    if (data?.inventory_transaction?.available[0]?.warehouse?.id === item.warehouse[0]?.id) {
                      return null; // Si coinciden, no renderizara el almacen
                    }

                    return (
                      <div key={`${index}-${itemIndex}`} style={{ paddingBottom: '3%' }}>
                        <Box
                          sx={{
                            padding: '8px 16px',
                            borderRadius: '8px',
                            border: '2px solid rgba(0, 0, 0, 0.2)',
                            backgroundColor: item?.available < userDetails?.cart?.cart_items[0]?.quantity ? 'rgba(0, 0, 0, 0.2)' : 'transparent'
                          }}
                        >
                          <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ flexGrow: 1, fontWeight: 'bold' }}>{item?.warehouse[0]?.name}</Typography>
                            {item?.available >= userDetails?.cart?.cart_items[0]?.quantity && (
                              <Checkbox
                                checked={selectedWarehouse === item?.id}
                                onChange={() => handleCheckboxChange(userDetails?.id, data?.id, item?.id)}
                                color="primary"
                                sx={{ padding: 0, borderRadius: '90%' }}
                              />
                            )}
                          </Box>


                          {item?.available >= userDetails?.cart?.cart_items[0]?.quantity ? (
                            <Typography variant="subtitle2" sx={{ marginTop: '4px' }}>
                              Disponible: <span style={{ fontWeight: 'bold' }}>{item?.available}</span>
                            </Typography>
                          ) : (
                            <div>
                              <Typography variant="subtitle2" sx={{ marginTop: '4px' }}>
                                Disponible: <span style={{ fontWeight: 'bold' }}>{item?.available}</span>
                              </Typography>
                              <Typography variant="subtitle2" sx={{ marginTop: '4px', color: 'red', fontWeight: 'bold' }}>
                                No tienes el stock suficiente
                              </Typography>
                            </div>
                          )}
                        </Box>
                      </div>
                    );
                  })}
                </div>
              ))}
            </Grid>
            <Grid container >
              <Grid sx={{ position: 'absolute', bottom: 50, left: 200 }}>
                <Button variant="contained" sx={{ width: '45%', marginRight: '13px' }} onClick={handleCloseModal}>Cancelar</Button>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  onClick={() => changeWarehouse(userDetails?.id, data?.id, selectedWarehouse)}// Id de la orden, Id del articulo/item, warehouse/almacen seleccionado
                  sx={{ width: '45%' }}
                >Confirmar</LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </ModalRight >
      ))}
    </>
  )
}

const ModalAvailableBranch = ({ openModal, setOpenModal, data, userDetails }) => {
  return (
    <BasicModal
      open={openModal}
      setOpen={setOpenModal}
      title="Disponibilidad en almacén de otras sucursales" >
      <Grid container justifyContent="space-around" alignItems="center">
        <Grid item xs={9}>
          {userDetails?.cart?.cart_items.map((data, index) => (
            <Grid container key={index}>
              <ImageSize img={data?.model?.image?.url} weight={2} />
              <Grid item ml={{ xs: "8px", md: "32px" }}>
                <h3>{data?.available?.model?.product?.name}</h3>
                <h4>{data?.model?.name}, {data?.presentation?.name}</h4>
              </Grid>
            </Grid>
          ))}
        </Grid>

        {userDetails?.cart?.cart_items.map((data, index) => (
          <Grid item xs={3} key={index}>
            <Grid
              container
              justifyContent={"start"}
              alignItems={"end"}
              flexDirection="column"
              sx={{ height: "64px" }}
            >
              <h4>{data?.available?.quantity}</h4>
            </Grid>
          </Grid>
        ))}

        {userDetails?.cart?.cart_items.map((data, index) => (
          <Grid key={index} item xs={12} mt={"32px"}>
            <GenericTable titles={["Sucursal", "Disponible", "Opción 1", "Opción 2"]}>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
              >
                <TableCell align="left">
                  <CardHeader
                    sx={{ padding: "0", margin: "0" }}
                    title={
                      <Typography variant="subtitle2" component="div">
                        Nombre
                      </Typography>
                    }
                    subheader={data?.inventory_transaction?.available}
                  />
                </TableCell>
                <TableCell align="left">{data?.available?.available}</TableCell>
                <TableCell align="left">
                  <Button>Cambiar</Button>
                </TableCell>
                <TableCell align="left">
                  <Button>Cambio manual</Button>
                </TableCell>
              </TableRow>
            </GenericTable>
          </Grid>
        ))}
      </Grid>
    </BasicModal>
  );
}

const ModalQuote = ({ openModal, setOpenModal, data, OpenService }) => {
  const [loading, setLoading] = useState(false)
  const [msg, sendNotification] = useNotification();
  const { logout } = useAuth();
  const [address, setAddress] = useState("")
  const [origin, setOrigin] = useState("")
  const [error, setError] = useState("")

  const formik = useFormik({
    initialValues: initValStoreQuote(data),
    /* enableReinitialize: true, */
    validationSchema: Yup.object(valSchStoreQuote()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      setError("")
      if (!origin) {
        setError("Este campo es requerido")
        setLoading(false);
        return;
      }
      if (formData.contentValue > 100000) {
        formData.contentValue = "99999";
      }
      let originAddress = address.find((e) => e.id == origin)
      console.log(originAddress)

      let formSent = {
        origin_address: originAddress?.street || "",
        origin_number: originAddress?.ext_number || "",
        origin_zip_code: originAddress?.postal_code || "",

        destination_address: data?.delivery_address?.street || "",
        destination_number: data?.delivery_address?.ext_number || "",
        destination_zip_code: data?.delivery_address?.postal_code || "",
        "package": {
          ...formData
        }
      }
      console.log(formSent)
      let setQuotation = new StoreQuotation();
      const response = await setQuotation.postDataAuth(formSent, logout);

      if (response?.data) {
        sendNotification(UseerrorOrsuccess(response))
        OpenService(response.data, formData, originAddress)
        setOpenModal(false);
        setLoading(false);
      } else {
        sendNotification(UseerrorOrsuccess(response))
      }

      /* OpenService()
      setOpenModal(false);  */
      setLoading(false);

    },
  });
  const getAddress = async () => {
    let irequestFilter = [];
    irequestFilter.push({ key: "is_shipping_address", value: `true` });
    let getDirec = new AddressManages();
    let response = await getDirec.filter(irequestFilter, logout);
    console.log(response.data)
    setAddress(response.data)
  }
  const handleChangeAddress = (e) => {
    setOrigin(e.target.value)
    console.log(e.target.value)
  }

  useEffect(() => {
    if (openModal) {
      getAddress()
    }
  }, [openModal])

  return (
    <BottonModalDiag
      open={openModal}
      setOpen={setOpenModal}
      title={{ "text": "Cotizar envío con Envioclick", "align": "left" }}
    >
      <Grid container  >
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing="12px" mt="2px">
              <Grid item xs={12}>

                {address && <FormControl fullWidth variant="outlined" size="small" >
                  <InputLabel i>
                    Origen
                  </InputLabel>
                  <Select
                    name="origen"
                    label="Origen"
                    value={origin}
                    onChange={handleChangeAddress}
                    error={
                      (error != "")
                    } >
                    {address && address.map((data) => (
                      <MenuItem key={data.id} value={data.id} >
                        {`${data?.street} ${data?.ext_number}, ${data?.suburb}, ${data?.postal_code}, ${data?.city}, ${data?.state}`} ({data?.business? "General": data?.branch.name})
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ display: error ? "" : "none", color: "red" }}>
                    {error ? error : ""}
                  </FormHelperText>
                </FormControl>}
                {/*   <TextField
                fullWidth
                name="origin_address"
                value={ `${data?.business?.address?.street} ${data?.business?.address?.ext_number}, ${data?.business?.address?.suburb}, ${data?.business?.address?.postal_code}, ${data?.business?.address?.city}, ${data?.business?.address?.state}` || ""}
               

                label="Origen"
            
                size="small"
                variant="outlined"
                autoComplete ='none'
                InputProps={{
                  readOnly: true,
                }}
           
  
              /> */}

              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="destination_address"
                  value={`${data?.delivery_address?.street} ${data?.delivery_address?.ext_number}, ${data?.delivery_address?.suburb}, ${data?.delivery_address?.postal_code}, ${data?.delivery_address?.city}, ${data?.delivery_address?.state}` || ""}


                  label="Destino"
                  size="small"
                  variant="outlined"
                  autoComplete='none'

                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  Características de tu paquete
                </Typography>

              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="weight"
                  value={formik.values.weight}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.weight && Boolean(formik.errors.weight)
                  }
                  label="Peso"
                  type="number"
                  size="small"
                  variant="outlined"
                  autoComplete='none'
                  InputProps={{
                    startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                  }}

                  helperText={
                    formik.touched.weight ? formik.errors.weight : ""
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="height"
                  value={formik.values.height}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.height && Boolean(formik.errors.height)
                  }
                  label="Alto"
                  type="number"
                  size="small"
                  variant="outlined"
                  autoComplete='none'
                  InputProps={{
                    startAdornment: <InputAdornment position="start">cm</InputAdornment>,
                  }}
                  helperText={
                    formik.touched.height ? formik.errors.height : ""
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="width"
                  value={formik.values.width}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.width && Boolean(formik.errors.width)
                  }
                  label="Ancho"
                  size="small"
                  type="number"
                  variant="outlined"
                  autoComplete='none'
                  InputProps={{
                    startAdornment: <InputAdornment position="start">cm</InputAdornment>,
                  }}
                  helperText={
                    formik.touched.width ? formik.errors.width : ""
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="length"
                  value={formik.values.length}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.length && Boolean(formik.errors.length)
                  }
                  label="Largo"
                  type="number"
                  size="small"
                  variant="outlined"
                  autoComplete='none'
                  InputProps={{
                    startAdornment: <InputAdornment position="start">cm</InputAdornment>,
                  }}
                  helperText={
                    formik.touched.length ? formik.errors.length : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  ¿Que envías?
                </Typography>

              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description && Boolean(formik.errors.description)
                  }
                  label="¿Que envías?"
                  size="small"
                  variant="outlined"
                  autoComplete='none'

                  helperText={
                    formik.touched.description ? formik.errors.description : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" columnSpacing="12px">

                  <Grid item xs={3}>
                    <LoadingButton
                      fullWidth
                      onClick={() => formik.resetForm()}
                      variant="outlined"

                    >
                      Limpiar
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={4}>
                    <LoadingButton
                      loading={loading}
                      fullWidth
                      type="submit"
                      variant="contained"

                    >
                      Cotizar
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </BottonModalDiag>
  );
}


const styleImage = {
  width: "100%",
  height: "auto"
}


const ModalService = ({ openModal, setOpenModal, data, orderData, listGuia, update }) => {
  const [value, setValue] = React.useState('');
  const [loading, setLoading] = useState(false)
  const [msg, sendNotification] = useNotification();
  const { logout } = useAuth();

  /* console.log(data) */
  const handleChange = (event) => {
    setValue(event.target.value);
    console.log(event.target.value)

  };
  /* console.log(data) */
  const handleSubmit = async () => {
    setLoading(true)
    /* console.log(data.data.rates.find((x=>x.idRate == value))) */
    let service = data.data.rates.find((x => x.idRate == value))
    let items = []
    let cart_items = []
    listGuia.forEach(element => {
      items.push({
        c_ClaveUnidadPeso: "XPK",
        c_ClaveProdServCP: "31181701",
        c_MaterialPeligroso: null,
        c_TipoEmbalaje: null,
        contentValue: parseFloat(element.price),
        units: element.quantity
      });
      cart_items.push(element.id)
    });
    console.log(items)

    let formSent = {
      id_rate: value,
      order: orderData.id,
      carrier: service.carrier,
      total: orderData.total,
      origin_address: data.originAddress.id,
      cart_items: cart_items,
      /* destination_address: orderData.delivery_address.id, */
      "package": {
        ...data.modal_info,
        items
      }
    }
    console.log(formSent)

    /*     "items": [
          {
        c_ClaveUnidadPeso: "XPK",
      c_ClaveProdServCP: "31181701",
      c_MaterialPeligroso: null,
      c_TipoEmbalaje: null,
      contentValue: 100.5,
      units: 1
          }
      ]
    }, */

    let setQuotation = new StoreGuide();
    const response = await setQuotation.postDataAuth(formSent, logout);

    if (response?.data) {
      sendNotification(UseerrorOrsuccess(response))

      setLoading(false);
    } else {
      sendNotification(UseerrorOrsuccess(response))
    }

    update()
    setLoading(false);
    setOpenModal(false)
  };

  useEffect(() => {
    if (!openModal) {
      setValue("")
    }

    return () => {
      setValue("")
    }
  }, [openModal])



  return (
    <BasicModal
      open={openModal}
      setOpen={setOpenModal}
      title="Seleccionar servicio" >

      {data.data?.rates &&
        <Grid container justifyContent="space-around"
          alignItems="center" sx={{ maxHeight: "80vh", overflowY: "auto" }} className="invisible-scrollbar" >
          <FormControl sx={{ width: "100%" }}>

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {data.data.rates.map((row, index) => (
                <Box
                  key={index}
                  sx={{ width: "100%", display: "flex", marginBottom: "16px" }}>

                  <Box align="left" sx={{ width: "30%", display: "flex", alignItems: "center" }}>
                    <ImageSize img={serviceImages(row.carrier)} custom={styleImage} />
                  </Box>

                  <Box align="left" sx={{ width: "30%" }}>
                    <CardHeader
                      sx={{ padding: "0", margin: "0" }}
                      title={
                        <Typography variant="subtitle2" component="div">
                          {row.carrier}
                        </Typography>}
                      subheader={<> <Typography variant="subtitle2" component="div">
                        {row.product}

                      </Typography>
                        <Typography variant="subtitle2" component="div">

                          {`Entrega aprox. ${row.deliveryDays}  ${(row.deliveryDays > 1 ? "días" : "día")}`}
                        </Typography>
                      </>}
                    />
                  </Box>

                  <Box align="left" sx={{ width: "30%" }}>
                    <CardHeader
                      sx={{ padding: "0", margin: "0" }}
                      title={
                        <Typography variant="subtitle2" component="div">
                          Precio
                        </Typography>}
                      subheader={<Typography variant="subtitle2" component="div">

                        {formatMoney(row.total)}
                      </Typography>}
                    />
                  </Box>

                  <Box align="left" sx={{ width: "10%" }}>
                    <FormControlLabel value={row.idRate} control={<Radio />} labelPlacement="start" label="" />
                  </Box>

                </Box>
              ))}

              {/*      <Box
              sx={{ width: "100%", display: "flex", marginBottom: "16px" }}>

                <Box align="left" sx={{width: "30%"}}> 
                <ImageSize img={"https://upload.wikimedia.org/wikipedia/commons/thumb/7/7f/Logo_de_Estafeta.svg/6834px-Logo_de_Estafeta.svg.png"} custom={styleImage}/>
              </Box>
              
              <Box align="left" sx={{width: "30%"}}> 
                  <CardHeader
                  sx={{padding: "0", margin: "0"}}
                  title={
                  <Typography variant="subtitle2" component="div">
                  Nombre
                  </Typography>}
                  subheader={"lfjlsfj"}
                />
              </Box>
              
              <Box align="left"  sx={{width: "30%"}}> 
                  <CardHeader
                  sx={{padding: "0", margin: "0"}}
                  title={
                  <Typography variant="subtitle2" component="div">
                  Nombre
                  </Typography>}
                  subheader={"lfjlsfj"}
                />
              </Box>

              <Box align="left"  sx={{width: "10%"}}> 
              <FormControlLabel value="male" control={<Radio />} labelPlacement="start"   label=""/> 
              </Box>
      
            </Box> */}

              {/* <FormControlLabel value="male" control={<Radio />} label="Male" /> */}
            </RadioGroup>
          </FormControl>

          <Grid item xs={12}>
            <Grid container justifyContent="flex-end" columnSpacing="12px">
              <Grid item xs={3}>
                {listGuia && <LoadingButton
                  loading={loading}
                  fullWidth
                  type="submit"
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  Seleccionar
                </LoadingButton>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
    </BasicModal>
  );
}