/*Aqui es la modal que refleja nuestro componente creado y el cual podemos estar editando su contenido, se refleja en "Elementos"*/
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    TextField,
    Paper,
    Switch
} from "@mui/material";
import { useSnackbar } from "notistack";
import ImgEdit from "../landing/ImgEdit";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import { BottonModalDiag } from "../GenericsModals";
import CountWords from "../CountWords";
import { ImageCreator, SubcomponentCreator } from "../../api/landing/creatorManages";
import { useAuth } from "user-context-fran-dev";
import NoImageFound from "../../assets/no_found.png"
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValCreatorSection, valSchCreatorSection } from "../../utils/formHelper";
import { LoadingButton } from "@mui/lab";
import { useNotification, UseerrorOrsuccess } from "../../hooks/snackBarResponse";
import Gallery from "./Gallery";
import Slider from '@mui/material/Slider';
import LinkType from "../keskiCreator/linkType";
import EditorConvertToHTML from "../RichText";
import { emoji } from 'react-draft-wysiwyg';


const EditModal = ({ open, setOpen, requirements, SECA, update, edit, setEdit, data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [msg, sendNotification] = useNotification();
    const [file, setFile] = useState();
    const [openImg, setOpenImg] = useState(false);
    const [loading, setLoading] = useState(false);
    const [req] = useState(SECA.getLayoutsFromSection(requirements.section).getFootPrint(requirements.layout).req)
    const { logout } = useAuth();
    const [openGallery, setOpenGallery] = useState(false);
    const [select, setSelect] = useState();
    const [newImg, setNewImg] = useState("")
    const [imgList, setImgList] = useState("");
    const [imgSize, setImgSize] = useState({ with: 0, height: 0 })
    const [description, setDescription] = useState(null);

    //ImgEdit states
    const UpdateImages = async () => {
        let get = new ImageCreator();
        let response = await get.getAllAuth(logout);
        sendNotification(UseerrorOrsuccess(response))
        setImgList(response.data);
    }

    useEffect(() => {
        UpdateImages()
        setSizeImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setSizeImage = () => {
        if (req["image"]) {
            switch (req["image"]) {
                case "background":
                    setImgSize({ width: 1280, height: 720, id: 1 }) //16:9
                    break;
                case "req_ver":
                    setImgSize({ width: 360, height: 640, id: 2 }) //9:16
                    break;
                case "req_hor":
                    setImgSize({ width: 640, height: 360, id: 3 }) //16/9
                    break;
                case "sqr":
                    setImgSize({ width: 512, height: 512, id: 4 }) // 1:1
                    break;
                default:
                    //setImgSize({with: 512, height:512, id: 4}) // 1:1
                    break;

            }
        }

    }


    /*   useEffect(()=>{
        console.log(requirements)
       console.log(SECA.getLayoutsFromSection(requirements.section).getFootPrint(requirements.layout).req) 
    
      },[]) */
    const setImage = (id) => {
        formik.values.image = id;
        // eslint-disable-next-line eqeqeq
        setNewImg(imgList.find((x) => x.id == id))
        setOpenGallery(false)
    };


    const putInfo = async (formData) => {
        let updateAddress = new SubcomponentCreator();
        const response = await updateAddress.editDataAuth(edit.id, formData, logout);
        if (response) {
            console.log('Entro a response')
            sendNotification(UseerrorOrsuccess(response))
            update(setLoading);
        }
    };
    const postInfo = async (formData) => {
        let updateAddress = new SubcomponentCreator();
        const response = await updateAddress.postDataAuth(formData, logout);
        sendNotification(UseerrorOrsuccess(response))
        update(setLoading)
            ();
    };


    const changeInput = (e) => {
        const files = e.currentTarget.files;
        const maxSize = 32 * 1024 * 1024; // 32 representa los mb que queremos convertir * 1024 es la cantidad de kb por 1 mb * 1024 es la cantidad de bytes en 1 kb

        console.log(files);
        console.log(URL.createObjectURL(files[0]));
        if (files.length <= 1) {
            if (files[0].size > maxSize) {
                enqueueSnackbar("La imagen es demasiado grande. El tamaño máximo permitido es de 32MB.", {
                    autoHideDuration: 5000,
                    variant: "warning",
                });
                return;
            }
            setFile(files[0]); //URL.createObjectURL()
            console.log(URL.createObjectURL(files[0]));
            setOpenImg(true);
            console.log("open");
        } else {
            enqueueSnackbar("Maximo 1 imagen ", {
                autoHideDuration: 1000,
                variant: "warning",
            });
        }
    };

    const UpdateImage = (file) => {
        (async () => {

            var formData = new FormData();
            formData.append('imgUpload', file);
            formData.append('type', 1);
            let get = new ImageCreator();

            let response = await get.uploadImg(formData, logout);
            update(setLoading);

            UpdateImages();
        })();
    };

    const formik = useFormik({
        initialValues: initValCreatorSection(req, edit),
        /*  enableReinitialize: true, */
        validationSchema: Yup.object(valSchCreatorSection(req)),
        onSubmit: async (formData, { resetForm }) => {
            setLoading(true);
            /*       formData.image = "6"f
                  formData.button_action = "dkjsklf" */
            formData.component = data.component.id
            if (formData?.image) {
                formData.image = select
            }

            console.log("form data", formData)

            if (edit) {

                await putInfo(formData)
                enqueueSnackbar("La informacion se actualizo correctamente", {
                    autoHideDuration: 3000,
                    variant: "success",
                })
            } else {
                postInfo(formData)
                enqueueSnackbar("El elemento se agrego correctamente", {
                    autoHideDuration: 3000,
                    variant: "success",
                })
            }

            resetForm()

            setOpen(false);

        },
    });
    /*   formik.values.image = '6';
      formik.values.button_action = 'hjgy7g'; */

    useEffect(() => {
        if (edit?.link && formik.values?.link !== edit?.link) {
            formik.values.button_action = "";
        }
        //console.log(req["image"])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.link])

    useEffect(() => {
        if (edit?.image?.id) {
            setSelect(edit.image.id)
        }
        //console.log(formik.values)
        /* https://wozapps-develop.s3.us-east-2.amazonaws.com/gerry/resources/landing/bbb84144-02cd-4cf8-a1be-21ba47e7c4fa.jpeg */
        return () => {
            setEdit("")
            formik.resetForm()
        }
    }, [])

    const handleOnClickDescription = (text) => {
        setDescription(text);

    };

    const toolbarConfigCreator = {
        options: ['inline', 'list', 'textAlign', 'colorPicker', 'emoji'],
        inline: {
            options: ['bold', 'italic', 'underline'],
        },
        list: {
            options: ['unordered', 'ordered'],
        },
        colorPicker: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            colors: [
                'rgb(0, 0, 0)', 'rgb(51, 51, 51)', 'rgb(77, 77, 77)', 'rgb(102, 102, 102)', 'rgb(128, 128, 128)', 'rgb(153, 153, 153)',             //NEGRO
                'rgb(204, 204, 204)', 'rgb(227, 227, 227)', 'rgb(237, 237, 237)', 'rgb(247, 247, 247)', 'rgb(255, 255, 255)', 'rgb(240, 240, 240)', //GRIS
                'rgb(40, 50, 78)', 'rgb(126, 150, 177)', 'rgb(169, 188, 199)', 'rgb(195, 208, 215)', 'rgb(206, 221, 223)', 'rgb(187, 206, 214)',    //GRIS OSCURO
                'rgb(84, 172, 210)', 'rgb(161, 198, 217)', 'rgb(184, 211, 224)', 'rgb(207, 224, 231)', 'rgb(229, 237, 238)', 'rgb(209, 228, 234)',  //AZUL TURQUESA
                'rgb(41, 105, 176)', 'rgb(143, 198, 223)', 'rgb(173, 216, 230)', 'rgb(180, 214, 232)', 'rgb(217, 230, 240)', 'rgb(239, 245, 250)',  //AZUL
                'rgb(71, 85, 119)', 'rgb(126, 150, 177)', 'rgb(169, 188, 199)', 'rgb(195, 208, 215)', 'rgb(206, 221, 223)', 'rgb(187, 206, 214)',   //AZUL GRIS
                'rgb(65, 168, 95)', 'rgb(139, 206, 161)', 'rgb(180, 217, 182)', 'rgb(220, 239, 225)', 'rgb(238, 248, 243)', 'rgb(207, 237, 223)',   //VERDE
                'rgb(26, 188, 156)', 'rgb(130, 199, 186)', 'rgb(166, 210, 204)', 'rgb(202, 236, 216)', 'rgb(238, 246, 243)', 'rgb(204, 232, 220)',  //CELESTE/AQUA
                'rgb(184, 49, 47)', 'rgb(216, 144, 142)', 'rgb(231, 170, 169)', 'rgb(244, 196, 194)', 'rgb(252, 225, 224)', 'rgb(249, 219, 219)',   //ROJO
                'rgb(247, 218, 100)', 'rgb(252, 234, 14)', 'rgb(253, 241, 175)', 'rgb(253, 248, 208)', 'rgb(254, 252, 232)', 'rgb(252, 251, 228)',  //AMARILLO
                'rgb(251, 160, 38)', 'rgb(253, 200, 139)', 'rgb(254, 216, 173)', 'rgb(254, 233, 207)', 'rgb(254, 248, 236)', 'rgb(255, 230, 195)',  //NARANJA
                'rgb(102, 51, 0)', 'rgb(166, 133, 100)', 'rgb(191, 166, 133)', 'rgb(216, 200, 166)', 'rgb(233, 216, 200)', 'rgb(250, 241, 233)',    //CAFE
                'rgb(147, 101, 184)', 'rgb(184, 144, 211)', 'rgb(202, 178, 220)', 'rgb(222, 205, 223)', 'rgb(239, 220, 238)', 'rgb(230, 213, 230)', //LILA
                'rgb(85, 57, 130)', 'rgb(144, 141, 195)', 'rgb(196, 168, 216)', 'rgb(211, 195, 227)', 'rgb(233, 221, 239)', 'rgb(215, 204, 235)',   //MORADO
                'rgb(255, 0, 255)', 'rgb(255, 204, 255)', 'rgb(255, 230, 255)', 'rgb(255, 243, 255)', 'rgb(255, 247, 255)', 'rgb(255, 250, 255)',   //ROSA/MAGENTA
            ],
        },
        emoji: {
            icon: emoji,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            emojis: [
            '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
            '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
            '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
            '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
            '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
            '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
            '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
            '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
            '✅', '❎', '💯',
            ],
        },
    };

    const maxWidthPaper = {
        '@media (max-width: 390px)': {
            maxWidth: '100%',
        },
        '@media (min-width: 391px) and (max-width: 475px)': {
            maxWidth: '100%',
        },
        '@media (min-width: 476px) and (max-width: 790px)': {
            maxWidth: '93vw',
        },
        '@media (min-width: 791px)': {
            maxWidth: '750px',
        }
    };

    return (
        <BottonModalDiag setOpen={setOpen} open={open} title={{ text: <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Editar elemento</Typography> }} size="90%">
            {openGallery && <Gallery open={openGallery} setOpen={setOpenGallery} setSelect={setSelect} select={select} title={"Selecciona una imagen"} setImage={setImage} type={imgSize.id} />}
            <Grid container>
                {file && openImg && imgSize && (
                    <ImgEdit
                        open={openImg}
                        handleClose={setOpenImg}
                        file={file}
                        size={imgSize} // { height: 360, width: 640 } 
                        update={UpdateImage}
                    />
                )}

                <Grid item xs={12} mt={"16px"}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing="22px">
                            {req["image"] && <Grid item xs={12}>
                                <Box>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Imagen</Typography>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '18px', marginRight: '5px' }}>Agrega una imagen horizontal de 1280 x 720 o equivalente a 16:9.</Typography>
                                        <label style={{ margin: "0 8px" }}>
                                            <Input
                                                hidden
                                                sx={{ display: "none" }}
                                                type="file"
                                                multiple
                                                onChange={changeInput}
                                            ></Input>
                                            <Button component="span">
                                                Subir imagen
                                            </Button>
                                        </label>
                                    </div>
                                    { (!req["image"] || (!newImg && !edit) ) && 
                                        <Button disabled={!req["image"]} onClick={() => setOpenGallery(true)} >
                                            Seleccionar de galería
                                        </Button>
                                    }
                                    
                                </Box>

                                {formik.touched.image && formik.errors.image && (
                                    <Typography variant="caption" color="error">
                                        {formik.errors.image}
                                    </Typography>
                                )}

                                {(edit || newImg) && <Grid item xs={12}>
                                    <Card sx={{ maxWidth: "300px", }}>
                                        <CardMedia
                                            component="img"
                                            height="200px"
                                            image={newImg ? newImg.url : edit?.image?.url || NoImageFound}
                                            alt={"none"}
                                            style={{
                                                transform: `scale(${formik.values.image_scale}) translateX(${formik.values.image_transform_x}%) translateY(${formik.values.image_transform_y}%)`,
                                            }}
                                        />
                                    </Card>
                                    <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <Button size="small" onClick={() => setOpenGallery(true)}>Remplazar</Button>
                                    </CardActions>
                                </Grid>}

                                <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '20px' }}>Posición de la imagen</Typography>
                                <Grid item xs={12} sx={{ paddingTop: '15px' }}>
                                    <Grid container spacing={2} style={{paddingTop: '24px'}}>
                                        <Grid item xs={4} sm={2} >
                                            <Typography variant="h6">Escalar</Typography>
                                        </Grid>
                                        <Grid item xs={8} sm={10}>
                                            <Slider
                                                value={formik.values.image_scale}
                                                onChange={(e, value) => formik.setFieldValue('image_scale', value)}
                                                aria-label="Small"
                                                valueLabelDisplay="on"
                                                step={0.05}
                                                min={0.1}
                                                max={2}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} style={{paddingTop: '24px'}}>
                                        <Grid item xs={4} sm={2}>
                                            <Typography variant="h6">Horizontal</Typography>
                                        </Grid>
                                        <Grid item xs={8} sm={10}>
                                            <Slider
                                                value={formik.values.image_transform_x}
                                                onChange={(e, value) => formik.setFieldValue('image_transform_x', value)}
                                                aria-label="Horizontal"
                                                valueLabelDisplay="on"
                                                step={1}
                                                min={-100}
                                                max={100}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} style={{paddingTop: '24px'}}>
                                        <Grid item xs={4} sm={2}>
                                            <Typography variant="h6">Vertical</Typography>
                                        </Grid>
                                        <Grid item xs={8} sm={10}>
                                            <Slider
                                                value={formik.values.image_transform_y}
                                                onChange={(e, value) => formik.setFieldValue('image_transform_y', value)}
                                                aria-label="Vertical"
                                                valueLabelDisplay="on"
                                                step={1}
                                                min={-100}
                                                max={100}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>}

                            {(req["title"] || req["description"] || req["subtitle"]) && (
                                <Grid item xs={12}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Texto</Typography>
                                </Grid>
                            )}


                            {req["title"] && <Grid item xs={12} >
                                <Typography variant="h6" gutterBottom component="div">
                                    Título
                                </Typography>

                                <Paper sx={{
                                    padding: '0px',
                                    ...maxWidthPaper
                                }}>
                                    {formik.values.title !== null && (
                                        <EditorConvertToHTML
                                            handleClick={(text) => {
                                                formik.setFieldValue("title", text);
                                                handleOnClickDescription(text);
                                            }}
                                            data={formik.values.title}
                                            toolbarConfig={toolbarConfigCreator}
                                        />
                                    )}
                                </Paper>

                                {formik.touched.title && formik.errors.title && (
                                    <Typography variant="caption" color="error">
                                        {formik.errors.title}
                                    </Typography>
                                )}
                            </Grid>
                            }

                            {req["subtitle"] && <Grid item xs={12} >
                                <Typography variant="h6" gutterBottom component="div">
                                    Subtitulo
                                </Typography>

                                <Paper sx={{
                                    backgroundColor: "#fff",
                                    padding: '8px',
                                    ...maxWidthPaper
                                }}>
                                    {formik.values.subtitle !== null && (
                                        <EditorConvertToHTML
                                            handleClick={(text) => {
                                                formik.setFieldValue("subtitle", text);
                                                handleOnClickDescription(text);
                                            }}
                                            data={formik.values.subtitle}
                                            toolbarConfig={toolbarConfigCreator}
                                        />
                                    )}
                                </Paper>

                                {formik.touched.subtitle && formik.errors.subtitle && (
                                    <Typography variant="caption" color="error">
                                        {formik.errors.subtitle}
                                    </Typography>
                                )}
                            </Grid>
                            }


                            {req["description"] && <Grid item xs={12} >
                                <Typography variant="h6" gutterBottom component="div">
                                    Descripción
                                </Typography>
                                <Paper sx={{
                                    backgroundColor: "#fff",
                                    padding: '8px',
                                    ...maxWidthPaper
                                }}>
                                    {formik.values.description !== null && (
                                        <EditorConvertToHTML
                                            handleClick={(text) => {
                                                formik.setFieldValue("description", text);
                                                handleOnClickDescription(text);
                                            }}
                                            data={formik.values.description}
                                            toolbarConfig={toolbarConfigCreator}
                                        />
                                    )}
                                </Paper>
                                {formik.touched.description && formik.errors.description && (
                                    <Typography variant="caption" color="error">
                                        {formik.errors.description}
                                    </Typography>
                                )}
                            </Grid>
                            }

                            {req["label"] && <Grid item xs={12}>
                                <Typography variant="h6" component="div" sx={{ paddingTop: '20px' }}>
                                    <strong>Etiqueta</strong>
                                </Typography>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '18px' }}>Agrega una etiqueta para destacar alguna palabra clave de tu contenido.</Typography>
                                    <Switch
                                        defaultChecked={formik.values.label_active}
                                        onChange={(e) => formik.setFieldValue('label_active', e.target.checked)}
                                        sx={{ display: "flex", alignItems: "center" }}
                                    />
                                </div>
                                {formik.values.label_active && (
                                    <>
                                        <TextField
                                            name="label"
                                            value={formik.values.label}
                                            label="Titulo"
                                            onChange={formik.handleChange}
                                            size="small"
                                            autoComplete='none'
                                            sx={{ mt: 2 }}
                                            fullWidth
                                            variant="outlined"
                                            error={formik.touched.label && Boolean(formik.errors.label)}
                                            helperText={formik.touched.label ? formik.errors.label : ""}
                                        />
                                        <TextField
                                            label=""
                                            fullWidth
                                            size="small"
                                            value={formik.values.label_color}
                                            onChange={formik.handleChange}
                                            name="label_color"
                                            sx={{
                                                marginTop: '15px',
                                                marginBottom: '10px',
                                                '& input[type="color"]': {
                                                    height: "45px",
                                                    width: "60px",
                                                    padding: 0,
                                                    paddingLeft: '2px',
                                                    borderRadius: '100%'

                                                }
                                            }}
                                            type="color"
                                            InputProps={{
                                                endAdornment: <Typography sx={{ marginLeft: "20px" }}>{formik.values.label_color}</Typography>,
                                                style: { display: "flex", alignItems: "center", padding: 0 }
                                            }}
                                        />
                                    </>
                                )}
                            </Grid>}

                            {req["button_title"] && req["button_action"] && (
                                <Grid item xs={12}>
                                    <Typography variant="h6" component="div" sx={{ paddingTop: '20px' }}>
                                        <strong>Botón</strong>
                                    </Typography>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '18px', marginRight: '5px' }}>Agrega un botón de llamado a la acción para que el usuario navegue en tu página.</Typography>
                                        <Switch
                                            defaultChecked={formik.values.button_active}
                                            onChange={(e) => formik.setFieldValue('button_active', e.target.checked)}
                                            sx={{ display: "flex", alignItems: "center" }}
                                        />
                                    </div>
                                </Grid>
                            )}

                            {formik.values.button_active && (
                                <>
                                    {req["button_title"] && (
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                fullWidth
                                                name="button_title"
                                                value={formik.values.button_title}
                                                onChange={formik.handleChange}
                                                error={formik.touched.button_title && Boolean(formik.errors.button_title)}
                                                label={`Titulo`}
                                                disabled={!req["button_title"]}
                                                size="small"
                                                variant="outlined"
                                                autoComplete='none'
                                                helperText={formik.touched.button_title ? formik.errors.button_title : ""}
                                            />
                                            <CountWords length={formik?.values?.button_title?.length ? formik?.values?.button_title?.length : 0} />
                                        </Grid>
                                    )}

                                    {req["button_action"] && (
                                        <Grid container>
                                            <Grid item xs={12} sm={12} sx={{ paddingTop: '10px', paddingLeft: '20px' }}>
                                                <FormControl fullWidth variant="outlined" size="small">
                                                    <InputLabel id="demo-simple-select-filled-label">Enlace</InputLabel>
                                                    <Select
                                                        name={"link"}
                                                        labelId="demo-simple-select-standard-label"
                                                        label={"Enalce"}
                                                        value={formik.values.link}
                                                        onChange={formik.handleChange}
                                                    >
                                                        <MenuItem key={1} value={1}>Página</MenuItem>
                                                        <MenuItem key={2} value={2}>Producto</MenuItem>
                                                        <MenuItem key={3} value={3}>Categoría</MenuItem>
                                                        <MenuItem key={4} value={4}>Enlace externo</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} sx={{ paddingTop: '30px' }}>
                                                {formik && (
                                                    <LinkType fullWidth formik={formik} />
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            )}


                            <Grid item xs={12} sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                                <LoadingButton
                                    loading={loading}

                                    type="submit"
                                    variant="contained">

                                    {edit ? "Actualizar" : "Agregar"}
                                </LoadingButton>

                            </Grid>

                        </Grid>

                    </form>
                </Grid>

            </Grid >

        </BottonModalDiag >
    );
};

export default EditModal;