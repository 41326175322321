import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import LoadingButton from '@mui/lab/LoadingButton';
import Container from "../../../layout/gridContainer";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../utils/cropImage";
import imageCompression from "browser-image-compression";
import { ProductModelImage } from "../../../api/productModel";
import { useAuth } from "user-context-fran-dev";
import { useParams } from 'react-router-dom'
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import "../../../scss/addImage.scss"



/* const marks = [
  {
    value: 0,
    label: '0°C',
  },
  {
    value: 90,
    label: '90°',
  },
  {
    value: 180,
    label: '180°',
  },
  {
    value: 270,
    label: '270°',
  },
]; 

const zooms = [
  {
    value: 1,
    label: '12%',
  },
  {
    value: 2,
    label: '25%',
  },
  {
    value: 3,
    label: '37%',
  },
  {
    value: 4,
    label: '49%',
  },
  {
    value: 5,
    label: '61%',
  },
  {
    value: 6,
    label: '73%',
  },
  {
    value: 7,
    label: '85%',
  },
  {
    value: 8,
    label: '75%',
  },
];  */

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CROP_AREA_ASPECT = 1 / 1;

const Output = ({ croppedArea, img, rotate }) => {
  const scale = 100 / croppedArea.width;
  const transform = {
    x: `${-croppedArea.x * scale}%`,
    y: `${-croppedArea.y * scale}%`,
    scale,
    width: "calc(100% + 0.5px)",
    height: "auto"
  };

  const imageStyle = {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height
  };

  return (
    <div
      className="output"
      style={{ paddingBottom: `${100 / CROP_AREA_ASPECT}%` }}
    >
      <img src={img} alt="" style={imageStyle} />
    </div>
  );
};

const AddImage2 = ({ open, handleClose, file, idModel, update }) => {

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);


  const [sendNotification] = useNotification();
  const { logout } = useAuth();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rotation, setRotation] = useState(0);


  const browCompre = async () => {
    //200kb maximo

    setLoading(true)
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 512,
      useWebWorker: true,
      alwaysKeepResolution: true,
    };

    let response;

    try {
      console.log(URL.createObjectURL(file))
      const imageFile = await getCroppedImg(
        file,
        croppedAreaPixels,
        rotation,
        zoom
      );
      console.log(URL.createObjectURL(imageFile))
      const compressedFile = await imageCompression(imageFile, options);
      /* console.log(compressedFile); */
      /*       console.log(
              `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
            );  */
      /* const size = compressedFile.size / 1024; */
      const myFile = new File([compressedFile], file.name, {
        type: file.type,
      });
      console.log(URL.createObjectURL(myFile));
      /* setCroppedImage(myFile); */
      //lo subes aqui memo

      let getCategory = new ProductModelImage();
      var formData = new FormData();
      formData.append('imgUploader', myFile);
      response = await getCategory.uploadDoc(idModel, formData, logout);
      sendNotification(UseerrorOrsuccess(response))

    } catch (error) {
      sendNotification(UseerrorOrsuccess({ msg: 'Ocurrio un error al subir la imagen', variant: 'error' }))
      console.log("error", error);

    } finally {
      update();
      handleClose();
      setLoading(false);
      sendNotification(UseerrorOrsuccess(response))
    }
  };

  /*       function valuetext(rotation) {
          return `${rotation}°`;
        }
      
        function zoomText(zoom) {
          return `${zoom}%`;
        } */



  return (
    <Container>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Editar
            </Typography>
            <LoadingButton autoFocus loading={loading} color="inherit" onClick={() => browCompre()}>
              Guardar
            </LoadingButton>
          </Toolbar>
        </AppBar>
        <>
          <div className="easy-cropper">
            <div className="size-center">
              <div className="title-editor1">
                <Typography variant="h6">Edita la imagen</Typography>
              </div>
              <div className="title-editor">
                <Typography variant="h6">Resultado</Typography>
              </div>
            </div>
            <div className="size-center">
              <div className="crop-container" >

                <Cropper
                  image={URL.createObjectURL(file)}
                  aspect={CROP_AREA_ASPECT}
                  crop={crop}
                  zoom={zoom}
                  rotation={rotation}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onRotationChange={setRotation}
                  onCropAreaChange={(croppedArea, croppedAreaPixels) => {
                    setCroppedArea(croppedArea);
                    setCroppedAreaPixels(croppedAreaPixels);
                  }}
                />
              </div>


              <div className="crop-result" > {croppedArea && <Output croppedArea={croppedArea} rotate={rotation} img={URL.createObjectURL(file)} />} </div>


            </div>
          </div>
        </>
      </Dialog>
    </Container>
  );
};

export default AddImage2;
