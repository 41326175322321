/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Container from "../../layout/gridContainer";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Divider,
  TextField,
  FormHelperText,
  FormGroup,
  Switch,
  ThemeProvider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { GenericHeaderTitle } from "../GenericHeader";
import { SectionsCreator, ImageCreator, SubcomponentCreator, ComponentCreator } from "../../api/landing/creatorManages";
import { useAuth } from "user-context-fran-dev";
import { useNavigate, useParams } from "react-router-dom";
import { Sections } from 'keski_lib_catalog'

import { getData } from "../../api/data/inicioData";
import { styled } from "@mui/styles";

import AddIcon from '@mui/icons-material/Add';
import BgStyle from "../keskiCreator/bgStyle";
import Element from "../keskiCreator/element";
import PreviewModal from "../keskiCreator/previewModal";
import EditModal from "../keskiCreator/editModal";

const DetailCreator = ({ view }) => {
  console.log('ID recibido:', view);

  const [data, setData] = useState()
  const [sections, setSections] = useState()
  const [bgStyle, setBgStyle] = useState(1);
  const [list, setList] = useState("");
  const { logout } = useAuth();
  const { id } = useParams()
  const [currentSection, setCurrentSection] = useState(view) //Ya del otro lado pasamos la prop view, el id, no se necesita volver a poner id useState(id)
  const Navigate = useNavigate();
  const [requirements, setRequirements] = useState()
  const [edit, setEdit] = useState()
  const [loading, setLoading] = useState(false)

  const SECA = new Sections()

  const [openEdit, setOpenEdit] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);

  const UpdateSection = async (setLoading) => {
    let get = new SectionsCreator();
    let response = await get.getAllByIdAuth(view, logout); //Checar aqui tambien que show (view, logout)
    setData(response.data);
    setRequirements({ section: response.data.type, layout: response.data.component.layout })
    setBgStyle(response.data.component.bg_type)
    setLoading(false)
  }

  const UpdateSectionsList = async () => {
    let get = new SectionsCreator();
    /*   let response = await get.getAllAuth(logout); */
    let irequestFilter = []
    irequestFilter.push({ 'key': 'view', 'value': view })
    let response = await get.filter(irequestFilter, logout);
    setSections(response.data)
  }

  const deleteElement = async (id) => {


    let updateAddress = new SubcomponentCreator();
    await updateAddress.deleteAuth(id, logout);


    UpdateSection(setLoading);
  };

  // Actualizar currentSection con el valor de view cuando view cambie cada vez que cliqueemos diferente seccion
  useEffect(() => {
    setCurrentSection(view);
  }, [view]);

  useEffect(() => {
    UpdateSection(setLoading)
    UpdateSectionsList()
    setList(SECA.getSectionList())

  }, [currentSection]);

  return (
    <Container>
      {requirements && openEdit && <EditModal data={data} open={openEdit} setOpen={setOpenEdit} requirements={requirements} SECA={SECA} update={UpdateSection} edit={edit} setEdit={setEdit} />}
      <PreviewModal open={openPreview} setOpen={setOpenPreview} SECA={SECA} data={data} />
      <Grid container>
        <Grid item xs={12}>
          {/*  <GenericHeaderTitle title={"Editar sección"}></GenericHeaderTitle>*/}
        </Grid>

        {/* <Grid item xs={12} mt={"16px"}>
          {sections && <FormControl
            fullWidth
            variant="standard"
            size="small"
          >
            <InputLabel id="demo-simple-select-filled-label">
              Sección
            </InputLabel>
            <Select
              fullWidth
              name={"seccion"}
              labelId="demo-simple-select-standard-label"
              label={"Sección"}
              value={currentSection}
              onChange={(e) => { setCurrentSection(e.target.value); }}
            >
            
              {sections && sections.map((data, index) => (
                <MenuItem key={index} value={data.id}>
                  {list[data.type]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
        </Grid> */}
        {/*     <Grid item xs={12} sx={{display: "flex", marginTop: "16px", justifyContent: "flex-end"}}>
             <Button onClick={()=>setOpenPreview(true)}>Vista previa</Button>     
        </Grid> */}
        <Grid item xs={12} mt={"16px"} >
          <Typography variant="body1" component="div">
            <strong>Estilo</strong>
          </Typography>
          <Typography variant="body2" component="div">
            Elija entre un color de fondo o una imagen horizontal de 1280 x 720 o equivalente a <strong>(16:9)</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} mt={"16px"}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControl
              sx={{ width: "100%" }}
              variant="outlined" /* variant="standard" */
              size="small"
            >
              <InputLabel id="demo-simple-select-filled-label">
                Tipo
              </InputLabel>
              <Select
                name={"Estilo"}
                labelId="demo-simple-select-standard-label"
                label={"Estilo"}
                value={bgStyle}
                onChange={(e) => setBgStyle(e.target.value)}
              >
                <MenuItem key={1} value={1}>
                  Color
                </MenuItem>
                <MenuItem key={2} value={2}>
                  Degradado
                </MenuItem>
                <MenuItem key={3} value={3}>
                  Imagen
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {data && <BgStyle bgStyle={bgStyle} data={data} UpdateSection={UpdateSection} setOpenPreview={setOpenPreview} />}
        </Grid>
        <Grid item xs={12} mt={"16px"}>
        </Grid>
      </Grid>

    </Container>
  );
};


const TextFiledPointer = styled(TextField)(({ theme }) => ({
  '& .MuiTextField-root ': {
    '& .MuiInputBase-root': {
      '& .MuiInputBase-root': {
        cursor: "pointer",
        backgroundColor: "red"
      }
    }

  },

}));



export default DetailCreator;
