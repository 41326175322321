import { BASE_PATH_DASH } from "../utils/constants";
import { BaseService } from 'base-service-fran-dev';


export default class AdminBusinessManages extends BaseService {
    constructor() {
        const baseUrl = BASE_PATH_DASH;
        const endPoint = 'business';
        super(baseUrl, endPoint)
    }
}
